<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12 mb-0 mt-0 pt-0 pb-0">
        <p style="font-size: 16px"><strong>Integração eContador</strong></p>
      </div>
      <div id="empresas" class="column is-12">
        <alt-datatable
          ref="datatable"
          customSearch
          isMultipleSelection
          :key="datatableKey"
          :pStatus="this.status"
          :pDataSource="this.dataSource"
          :pData="this.empresas"
          :pHideButtonMore="this.hideButtonMore"
          :pQuantidadeDeItems="this.page.total"
          :nextPage="this._carregarListagem"
          :idDivUtilizadaPaginacao="'empresas'"
          :pShowButtons="false"
          :customHighlighters="customHighlighters"
          :isShowMultipleSelection="false"
          @search="aoPesquisar"
        >
          <template v-slot:new-item>
            <integracao-em-lote
              :itens="dataSource.itensSelected"
              @atualizarStatus="this.status = $event"
              @atualizar="_carregarListagem"
            />
          </template>
          <template #status="{ item }">
            <div
              v-if="
                (item.empresaAtiva === false && item.status == 'Ativa') ||
                item.dataFimAtividade != null
              "
              class="is-flex is-align-items-center"
              style="align-items: center; padding-left: 20px"
            >
              <comp-switch
                :pName="item.cdempresa"
                :pId="item.cdempresa"
                :pLabel="item.st_dc ? '' : ''"
                :pValue="item.st_dc ? '1' : null"
                :item="item"
                :integracao="this._integrar"
              />
              <!-- @cliqueSwitch="this._integrar" -->
            </div>

            <div v-if="item.empresaAtiva == true">
              <Tag
                style="min-width: 80%; background-color: #c7c7c7 !important"
                caption="Empresa inativa"
                class="is-neutral is-light"
              />
            </div>
            <div v-if="item.status == 'À concluir'">
              <Tag
                style="min-width: 80%; background-color: #c7c7c7 !important"
                caption="Cadastro à concluir"
                class="is-neutral is-light"
              />
            </div>
            <!-- <div v-if="item.dataFimAtividade != null">
              <Tag
                style="min-width: 100%; background-color: #c7c7c7 !important"
                caption="Encerrada"
                class="is-neutral is-light"
              />
            </div> -->
          </template>
        </alt-datatable>
        <AtualizarDados
          v-if="(this.integra === true && this.exibirBotaoAtualizar)"
          :itens="dataSource.itensSelected"
          @atualizacao-concluida="aoConcluirAtualizacao"
        />
      </div>
    </div>
    <div v-if="(this.integra === false)">
      <div
        class="is-flex is-align-items-center is-justify-content-center"
        style="height: 400px"
      >
        <div
          class="box is-flex is-align-items-center is-justify-content-center"
          style="
            background-color: lightgoldenrodyellow;
            width: 70%;
            height: 30%;
          "
        >
          <p class="is-size-6"> No momento seu cadastro não está ativo para a comunicação entre o Pack Online e o eContador, para maiores informações e solicitação de acesso, entre em contato com o suporte Alterdata</p>
        </div>
      </div>
    </div>
    <div v-if="(page.total === 0 && this.integra === true)">
      <div
        class="is-flex is-align-items-center is-justify-content-center"
        style="height: 400px"
      >
        <div
          class="box is-flex is-align-items-center is-justify-content-center"
          style="
            background-color: lightgoldenrodyellow;
            width: 50%;
            height: 50%;
          "
        >
          <p class="is-size-6">Não existem dados para visualizar</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from '../../../util/utils.js'
import { empresaService as service } from '../../Empresa/service/empresa-service.js'
import Empresa from '../../Empresa/model/empresa.js'
import AltDatatable from '../../../components/alt-component/Datatable/index.vue'
import Switch from '../../../components/alt-component/Switch/index.vue'
import { integracaoeContador } from '../../../IntegracaoeContador/integracao-e-contador.js'
import { validadorDeInscricaoFederal } from '../../../util/InscricaoFederal.js'
import Tag from '@alterdata/component-vue/Tag'

import IntegracaoEmLote from './IntegracaoEmLote'
import { toast } from 'bulma-toast'
import AtualizarDados from './AtualizarDados/index.vue'
import Toast from '../../../components/ListaToastAviso/Toast/model/Toast'
import { mixin } from '../../../util/mixin'

export default {
  name: 'app',
  components: {
    'alt-datatable': AltDatatable,
    'comp-switch': Switch,
    IntegracaoEmLote,
    Tag,
    AtualizarDados
  },
  mixins: [mixin],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'cdempresa',
            label: 'Código',
            format: this._formatarCodigoEmpresa,
            style: 'width:5%'
          },
          {
            name: 'nomeCompleto',
            label: 'Nome',
            style: 'width:20%'
          },
          {
            name: 'numeroCGC',
            label: 'CNPJ/CPF',
            style: 'width:10%',
            format: this._formatarCPFCNPJ
          },
          {
            name: 'status',
            label: 'Integrar',
            style: 'width:5%; align-items:center;padding-left:40px;',
            slot: true
          }
        ],
        selected: null,
        cursor: null,
        loadMore: null,
        itensSelected: [],
        itensDisabled: [],
        filter: () => this._carregarListagem()
      },
      socemps: [],
      page: [],
      empresas: [],
      todasAsEmpresas: [],
      cadastrandoOuEditando: false,
      editando: false,
      item: new Empresa(),
      status: '',
      hideButtonMore: true,
      listagemVisivel: true,
      showTitle: false,
      pagina: 0,
      termoPesquisado: '',
      datatableKey: 0,
      checado: '',
      apiResposta: null,
      integra: null,
      exibirBotaoAtualizar: false
    }
  },
  methods: {
    async _carregarListagem (pagina = 0) {
      await this.integracaoEmpresaPodeIntegrar()
      this.dataSource.itensSelected = []
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, pagina)
      } else {
        this.exibirBotaoAtualizar = false
        this.status = 'is-loading'
        try {
          this.empresas = []
          if (this.integra) {
            this.page = await service.get(
              `/api/v1/empresa-cadastros-vinculados?page=${pagina}`
            )
            this.empresas = this.page.content
            this.status = ''
            this._ordenarGridPorCodigo()
            this.pagina = pagina
            this.exibirBotaoAtualizar = true
          } else {
            this.empresas = []
            this.status = 'is-warning'
          }
          if (this.page.total === 0) {
            this.status = 'is-empty'
          }
        } catch {
          this.status = 'is-danger'
        }
      }
      this.empresasDesativadas()
    },
    _formatarCPFCNPJ (valor) {
      if (!valor) {
        return ''
      }
      const inscricaoFederal = valor.replace(/[^\d]+/g, '')
      if (inscricaoFederal.length === 11) {
        return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricaoFederal)
      } else if (inscricaoFederal.length === 14) {
        return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricaoFederal)
      }
    },
    _formatarCodigoEmpresa (codigo) {
      return utils.strZero(codigo, 5)
    },
    async _integrar (item) {
      const idEmpresa = item.cdempresa
      if (!item.st_dc) {
        const res = await integracaoeContador.integrar(idEmpresa)
        if (!res.retorno) {
          const notificacao = new Toast({ tipo: 'warning', titulo: 'Não foi possível realizar a integração no momento.', subtitulo: 'Tente novamente mais tarde.' })
          this.adicionarNotificacao(notificacao)
          await integracaoeContador.desintegrar(idEmpresa)
        } else {
          this.mostrarToast(
            `Empresa ${utils.strZero(idEmpresa, 5)} integrada com sucesso`,
            'success'
          )
          item.st_dc = !item.st_dc
        }
      } else {
        const res = await integracaoeContador.desintegrar(idEmpresa)
        if (!res) {
          const notificacao = new Toast({ tipo: 'warning', titulo: 'Não foi possível desfazer a integração no momento.', subtitulo: 'Tente novamente mais tarde.' })
          this.adicionarNotificacao(notificacao)
          await integracaoeContador.integrar(idEmpresa)
        } else {
          item.st_dc = !item.st_dc
        }
      }
    },
    mostrarToast (message, type) {
      toast({
        type: `is-${type}`,
        message: `${message}`,
        duration: 3500
      })
    },
    limparPesquisa () {
      this.termoPesquisado = ''
      this.$refs.datatable.$refs.search.value = ''
      this.datatableKey++
      this._carregarListagem()
    },
    _ordenarGridPorCodigo () {
      this.empresas.sort(function (a, b) {
        return a.cdempresa < b.cdempresa
          ? -1
          : a.cdempresa > b.cdempresa
            ? 1
            : 0
      })
    },

    async buscar (search, pagina = 0) {
      this.status = 'is-loading'
      try {
        if (this.termoPesquisado) {
          this.empresas = []
          const page = await service.get(
            `/api/v1/empresa/search?q=${encodeURIComponent(
              search
            )}&page=${pagina}`
          )
          this.page = page
          this.pagina = pagina
          this.empresas = this.page.content
          this.empresas.forEach((empresa) => {
            empresa.status = empresa.empresaAtiva ? 'Inativa' : 'Ativa'
            if (!empresa.numeroCGC || !empresa.inscricaoEstadual) {
              empresa.status = 'À concluir'
            }
          })
          this.status = ''
          this._ordenarGridPorCodigo()
        } else {
          await this._carregarListagem()
        }
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },

    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this._carregarListagem()
    },

    _retornarTextoTipoCalculoIR (tipo) {
      const tipos = {
        S: 'Simples',
        R: 'Lucro Real',
        L: 'Lucro Presumido',
        A: 'Lucro Arbitrado',
        I: 'Imunes',
        T: 'Isentas',
        M: 'MEI',
        X: 'Pessoa Física'
      }
      return tipos[tipo]
    },

    _retornarCodigoEmpresaFormatado (codigo) {
      return utils.strZero(codigo, 5)
    },

    async proximoCodigo () {
      const resposta = await service.get('/api/v1/empresa-proximo-codigo')
      return resposta.codigo
    },
    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils
        .removeAcentos(stringfied)
        .toLowerCase()
        .indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    },

    async integracaoEmpresaPodeIntegrar () {
      if (this.integra === null) {
        this.status = 'is-loading'
      }
      if (this.integra !== true) {
        try {
          await integracaoeContador.podeIntegrar().then(res => {
            this.integra = res
          })
        } catch (error) {
          if (error.message === 'Erro 401. Crm ou cpf sem acesso ao EContador') {
            this.integra = false
          } else {
            const notificacao = new Toast({ tipo: 'warning', titulo: 'Não foi possível fazer a comunicação no momento.', subtitulo: 'Tente novamente mais tarde.' })
            this.adicionarNotificacao(notificacao)
          }
        }
      }
    },

    empresasDesativadas () {
      const empresasDesativadas = this.empresas.filter(
        (empresa) => empresa.empresaAtiva || empresa.status === 'À concluir'
      )
      this.dataSource.itensDisabled = empresasDesativadas
    },

    aoConcluirAtualizacao () {
      this.$refs.datatable.dataSource.itensSelected = []
    }
  },
  created () {
    this._carregarListagem()
  }
}
</script>

<style scoped lang="scss">
@import '../../../../node_modules/bulma/bulma.sass';
@import '../../../../node_modules/bulma-switch';
</style>
