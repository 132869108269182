import { microServiceRequest } from '@packonline/packonline-reutilizaveis-frontend/core/services/micro-service-request'
import { env } from '@/env'
const API = '/api/v1/livros/balancete'
export default class ConfiguraçoesAdicionais {
  constructor (obj) {
    this.stCalculaAcumulados = null
    this.stImprimeAnalise = null
    this.stTotaisDescricao = null
    this.BalanceteImprimirTotaisSemAtivoPassivoCompensado = null
    this.AglutinaSintetica = null
    this.ComSaldo = null
    this.ComSaldoAnteriorOuAtual = null
    this.stTodasAsContas = null
    this.stContasComMovimento = null
    this.TodasAsContasPlanoContas = null
    this.ModeloExcel = null
    this.stAsteriscos = null
    this.stImprimeMascara = null
    this.stSomenteSaldoAtual = null
    this.stCodigoDescricaoBal = null
    this.stOrdemClassificacao = null
    this.stImpressaoTxt2 = null
    this.stBalanceteMatricial = null
    this.ImprimeCNPJBalancete = null
    this.ImprimeNIREBalancete = null
    this.ImprimeBUREAUBalancete = null
    this.ImprimeAsteriscosFimRelatorioBR = null
    this.StImprimeDataHora = null
    this.StClassExterna = null
    this.stBloqueiaImpressao = null

    if (obj) {
      Object.assign(this, obj)
    }
  }

  static async salvaOpcoes (objBalancete) {
    await microServiceRequest.post(env.msContabil, `${API}/atualizar-balancete-configuracoes-adicionais`, objBalancete)
  }

  static testaOpcaoComNOuNula (opcao) {
    return opcao === 'N' || !opcao
  }
}
