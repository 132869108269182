<template>
  <div class="container">
    <div class="columns is-multiline">
      <div id="data-table-usuario" class="column is-12">
        <alt-data-table
          ref="datatable"
          idDivUtilizadaPaginacao="data-table-usuario"
          customSearch
          :pDataSource="this.dataSource"
          :pData="this.usuarios"
          :pStatus="this.status"
          :pHideButtonMore="this.hideButtonMore"
          :pQuantidadeDeItems="this.page.total"
          :nextPage="this._carregarListagem"
          @search="aoPesquisar"
          :modalDelecaoVisivel="this.verificarUsuario"
          :customHighlighters="customHighlighters"
          :desativar-atalhos="quickviewAtivo || mostrarModal"
        >
          <template v-slot:new-item>
            <Button
              type="primary"
              caption="Novo usuário"
              :disabled="quickviewAtivo"
              :title="shortkeys.MSG_KEY_NOVO"
              @click="this._criarUsuario()"
            />
          </template>
          <template #status="{ item }">
            <div
              class="is-flex is-justify-content-center is-align-items-center"
            >
              <Tag
                v-if="item.nmcurto === 'SUPERVISOR'"
                caption="Administrador"
                class="is-info"
                style="min-width: 100%; background-color: #0068b3 !important"
              />
              <Tag
                v-if="!item.usuarioDesativado"
                style="min-width: 100%; background-color: #c7c7c7 !important"
                caption="Inativo"
                class="is-neutral is-light"
              />
            </div>
          </template>
        </alt-data-table>
      </div>
    </div>
  </div>
  <ModalMessage ok-caption="Excluir usuário" cancel-type="primary" :message="mensagem" :active="mostrarModal" @close="aoFechar" />

</template>

<script>

import Button from '@alterdata/component-vue/Button'
import Tag from '@alterdata/component-vue/Tag'
import DataTable from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import Usuario from './model/Usuario'
import CadastroEdicaoUsuario from './cadastroEdicaoUsuario.vue'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { mixinPesquisa } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-pesquisa'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { toast } from 'bulma-toast'
import ModalMessage from '@alterdata/component-vue/ModalMessage/index.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'

export default {
  name: 'Usuarios',
  components: {
    'alt-data-table': DataTable,
    Button,
    Tag,
    ModalMessage
  },
  mixins: [mixin, mixinFocoPrimeiroElementoDataTable, mixinPesquisa],
  inject: ['setActiveSection', 'addShortcut'],
  data () {
    return {
      showTitle: false,
      status: '',
      dataSource: {
        columns: [
          {
            name: 'nmcurto',
            label: 'Login',
            style: 'width: 20%'
          },
          {
            name: 'nmusuario',
            label: 'Nome',
            style: 'width: 20%'
          },
          {
            name: 'email',
            label: 'Email',
            style: 'width: 30%'
          },
          {
            name: 'status',
            label: '',
            style: 'width: 10%;height: 100%',
            slot: true
          }
        ],
        selected: null,
        cursor: null,
        loadMore: null,
        save: (item) => console.log(item),
        filter: () => this._carregarListagem(),
        select: (item) => this._editarUsuario(item),
        delete: (item) => item.nmcurto !== 'SUPERVISOR' ? this.prepararExclusao(item) : '',
        disableDeleteButtonIf: (item) => item.nmcurto === 'SUPERVISOR',
        disableEditButtonIf: () => false
      },
      page: [],
      usuarios: [
        {
          login: 'teste.funcao.setor',
          nome: 'Testes',
          email: 'teste.funcao.setor@alterdata.com.br'
        }
      ],
      editando: false,
      item: new Usuario(),
      hideButtonMore: false,
      listagemVisivel: true,
      pagina: 0,
      titulo: 'Edição',
      visivel: false,
      termoPesquisado: '',
      usuario: null,
      mostrarModal: false,
      modalDelecao: true,
      preDelete: undefined,
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Efetuar+Configura%C3%A7%C3%B5es'

    }
  },
  watch: {
    mostrarModal (val) {
      val ? this.setActiveSection('modalExclusao') : this.setActiveSection('listagemUsuario')
    },
    quickviewAtivo (val) {
      val ? this.setActiveSection('quickviewUsuario') : this.setActiveSection('listagemUsuario')
    }
  },
  computed: {
    mensagem () {
      if (this.preDelete) {
        return `Deseja excluir o usuário "${this.preDelete.nmcurto}"?`
      } else {
        return 'Deseja excluir o usuário ?'
      }
    },
    quickviewAtivo () {
      return this.$root.paramentrosQuickView.componenteSlotQuickView
    }
  },
  unmounted () {
    this.limparParametrosQuickView()
  },
  methods: {
    async verificarUsuario (usuario) {
      const usuarioid = await service.get(`/api/v1/adusystem/${usuario.idusuario}`)
      this.usuario = usuarioid.total > 0
      return false
    },
    prepararExclusao (item) {
      if (this.indexador) {
        this._deletarUsuario(item)
      } else {
        this.mostrarModal = true
        this.preDelete = item
      }
    },
    async aoFechar (event) {
      if (event === 'ok') {
        await this._deletarUsuario(this.preDelete)
      } else {
        this.mostrarModal = false
      }
    },
    async _carregarListagem (pagina = 0) {
      this.status = 'is-loading'
      if (this.termoPesquisado) {
        await this._buscar(pagina)
      } else {
        try {
          this.usuarios = []
          this.page = await service.get(`/api/v1/adusystem?page=${pagina}&sort=nmcurto`)
          this.usuarios = this.page.content
          this.status = ''
          this._ordenarGridPorCodigo()
          this.pagina = pagina
          if (this.page.total === 0) {
            this.status = ''
            this.focarNaPesquisa()
          } else {
            this.focarNoPrimeiroElementoDataTable()
          }
        } catch {
          this.status = 'is-danger'
        }
      }
    },

    async _buscar (pagina) {
      try {
        this.usuarios = []
        this.page = await service.get(
          `/api/v1/adusystem/search?q=${encodeURIComponent(
            this.termoPesquisado
          )}&page=${pagina}&sort=nmcurto`
        )
        this.usuarios = this.page.content
        this.status = ''
        this.pagina = pagina
        if (this.page.total === 0) {
          this.focarNaPesquisa()
        } else {
          this.focarNoPrimeiroElementoDataTable()
        }
      } catch {
        this.status = 'is-danger'
      }
    },

    aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      this._carregarListagem()
    },

    _criarUsuario () {
      this.editando = false
      this.item = new Usuario()
      this.titulo = 'Criação de usuário'
      this._gerarCadastroEdicao()
    },
    _editarUsuario (item) {
      this.titulo = 'Edição de usuário - ' + item.nmcurto
      this.editando = true
      Object.assign(this.item, item)
      this._gerarCadastroEdicao()
    },
    async _deletarUsuario (paramUsuario) {
      const resposta = await service.delete('/api/v1/adusystem', paramUsuario, paramUsuario.idusuario)
      if (resposta.ok) {
        toast({
          message: 'Excluímos!',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true
        })
        const itemIndex = this.usuarios.findIndex(
          (item) => item.idusuario === paramUsuario.idusuario
        )

        this.usuarios.splice(itemIndex, 1)
        const qtd = this.usuarios.length
        if (qtd === 0) {
          await this._carregarListagem()
          this.focarNoPrimeiroElementoDataTable()
        }
        await this._ordenarGridPorCodigo()
        this.focarNoPrimeiroElementoDataTable()
        this.mostrarModal = false
      }
    },

    _gerarCadastroEdicao () {
      const { paramentrosQuickView } = this.$root
      paramentrosQuickView.componenteSlotQuickView = null
      this.$nextTick(() => {
        paramentrosQuickView.componenteSlotQuickView = (
          <CadastroEdicaoUsuario
            pItem={this.item}
            titulo={this.titulo}
            editando={this.editando}
            onEditando={(event) => {
              this.editando = event
            }}
            onCarregarListagem={() => this._carregarListagem()}
            focar-table={this.focarNoPrimeiroElementoDataTable}
            link-ajuda={this.linkAjuda}
          />
        )
        paramentrosQuickView.tituloTela = this.titulo
        paramentrosQuickView.minimizavel = false
        paramentrosQuickView.ocultarBotaoGravar = false
        paramentrosQuickView.tituloBotaoFechar = 'Cancelar'
        paramentrosQuickView.atalhoBotaoGravar = shortkeys.MSG_KEY_GRAVAR
        paramentrosQuickView.atalhoBotaoFechar = shortkeys.MSG_KEY_ESC
        paramentrosQuickView.estilo =
            'position: fixed; top: 35px; right: 15px; width: 35%; z-index: 5;'
        paramentrosQuickView.alturaQuickView = '78vh'
        paramentrosQuickView.impedirFechamentoDoQuickViewAoCancelar = true
      })
    },
    async _ordenarGridPorCodigo () {
      this.usuarios.sort(function (a, b) {
        if (a.nmusuario === b.nmusuario) {
          return 0
        } else {
          return a.nmusuario > b.nmusuario ? 1 : -1
        }
      })
    },
    direcionaAjuda () {
      this.$nextTick(() => {
        window.open(this.linkAjuda, '_blank').focus()
      })
    },
    ligarAtalhosListagem () {
      const section = 'listagemUsuario'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this._criarUsuario)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },

    ligarAtalhosModalExclusao () {
      const section = 'modalExclusao'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_ESC, () => (this.mostrarModal = false))
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    }
  },
  async created () {
    this.ligarAtalhosModalExclusao()
    this.ligarAtalhosListagem()
    await this._carregarListagem()
    this.focarNoPrimeiroElementoDataTable()
  }
}
</script>

<style></style>
