import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIERS extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 10) {
      return false
    }

    const peso = [2, 9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)
    const digito = this.inscricao.substring(this.inscricao.length - 1)

    let soma = 0

    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    const resultado = (soma % 11) <= 1 ? 0 : 11 - (soma % 11)

    return resultado === Number.parseInt(digito)
  }
}
