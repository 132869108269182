<template>
  <div class="columns is-multiline">
    <div class="column is-1 mr-3">
      <alt-button
        :disabled="itens.length === 0 || isCarregando || itensIntegrados"
        :loading="atualizandoDados"
        style="padding-right: 3rem; padding-left: 3rem"
        class="mr-3"
        type="terciary"
        icon-only="sync"
        size="large"
        @click="atualizarDados"
      />
    </div>
    <div class="column is-9">
      <a style="color: white" @click="this.exibirDetalhes = !this.exibirDetalhes">
        <span class="icon is-small">
          <em v-if="!exibirDetalhes" class="fas fa-angle-right" style="color:gray"></em>
          <em v-else class="fas fa-angle-down" style="color:gray"></em>
        </span>
        <span style="color: gray">Detalhes do item</span>
      </a>
      <div
              style="
                background-color: lightgoldenrodyellow;
                padding: 20px;
                padding-left: 1px;
                padding-right: 60px;
                margin-top: 10px;
                margin-left: 0.2rem;
              "
              v-if="this.exibirDetalhes"
            >
              <p style="font-size: 14px; margin-left: 20px">
                Utilize essa opção caso identifique que os dados cadastrais de alguma empresa esteja desatualizado no eContador. <br>
                Para isso basta marcar o checkbox que fica ao lado esquerdo do nome da(s) empresa(s) desejada(s) que esteja(m) integrada(s) e em seguida clicar no botão para atualizar.
              </p>
            </div>
    </div>
  </div>
</template>

<script>
import Toast from '../../../../components/ListaToastAviso/Toast/model/Toast'
import { service } from '../../../../service'
import { mixin } from '../../../../util/mixin'
import Button from '@alterdata/component-vue/Button/index.vue'
import { toast } from 'bulma-toast'

const API = '/api/v1/integracao-e-contador/'

export default {
  components: { 'alt-button': Button },
  emits: ['atualizacao-concluida'],
  props: {
    itens: {
      type: Array,
      default: () => []
    }
  },
  mixins: [mixin],
  data () {
    return {
      isCarregando: false,
      atualizandoDados: false,
      exibirDetalhes: false
    }
  },
  methods: {
    async atualizarDados () {
      const filtro = item => item.st_dc
      const empresasId = this._extrairIds(filtro)
      try {
        if (empresasId.length > 0) {
          this.isCarregando = true
          this.atualizandoDados = true
          await service.post(
            API.concat('atualizar-dados-econtador'),
            empresasId
          )
          this.atualizandoDados = false
          this.isCarregando = false
        }
        toast({
          type: 'is-success',
          message: 'Dados atualizados com sucesso',
          duration: 3500
        })
        this.$emit('atualizacao-concluida')
      } catch (err) {
        const htmlStr = `<p>${err.message}</p><br>`
        const notificao = new Toast({ tipo: 'warning', titulo: 'Não foi possível atualizar os dados no eContador para todas as empresas.', subtitulo: htmlStr })
        this.adicionarNotificacao(notificao)
      } finally {
        this.isCarregando = false
        this.atualizandoDados = false
      }
    },
    _extrairIds (filtro) {
      return this.itens.filter(filtro).map((empresa) => empresa.cdempresa)
    }
  },
  computed: {
    itensIntegrados () {
      return this.itens.filter(item => item.st_dc === false).length > 0
    }
  }
}
</script>

<style>

</style>
