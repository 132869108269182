<template>
  <div class="container">
    <div class="column is-full">
      <page-title>Configurações</page-title>
      <div class="columns is-multiline">
        <div class="field column is-12">
          <div class="tabs">
            <ul>
              <li data-group="aba-smtp" :class="{ 'is-active': smtpVisivel }">
                <a @click="ativarAba('smtp')">Envio de e-mails</a>
              </li>
              <li data-group="aba-infcontador" :class="{ 'is-active': infContadorVisivel }">
                <a @click="ativarAba('infcontador')">Informações do contador</a>
              </li>
              <li data-group="aba-usuario" :class="{ 'is-active': abaUsuarioVisivel }">
                <a @click="ativarAba('usuario')">Cadastro de usuários</a>
              </li>
              <li data-group="aba-gerais" :class="{ 'is-active': geraisVisivel }">
                <a @click="ativarAba('gerais')">Configurações gerais</a>
              </li>
              <li data-group="aba-integracaoecontador" :class="{ 'is-active': econtadorVisivel }">
                <a @click="ativarAba('integracaoecontador')"
                  >Integração eContador</a
                >
              </li>
              <li data-group="aba-relatorios" :class="{ 'is-active': relatoriosVisivel }">
                <a @click="ativarAba('relatorios')"
                  >Relatórios contábeis</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="field column is-12">
          <smtp ref="smtp" v-if="smtpVisivel" />
          <infContador ref="infcontador" v-if="infContadorVisivel" />
          <usuario v-if="abaUsuarioVisivel" />
          <gerais ref="gerais" v-if="geraisVisivel" />
          <integracao-econtador v-if="econtadorVisivel" />
          <RelatoriosContabeis v-if="relatoriosVisivel" />
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import Gerais from './Gerais/index.vue'
import InfContador from './InfContador/index.vue'
import Smtp from './Smtp/index.vue'
import Usuario from './Usuario/index.vue'
import IntegracaoEcontador from './IntegracaoEcontador/index.vue'
import RelatoriosContabeis from './RelatoriosContabeis/index.vue'
import PageTitle from '@/components/PageTitle/index.vue'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'

export default {
  components: {
    Smtp,
    InfContador,
    Usuario,
    Gerais,
    IntegracaoEcontador,
    RelatoriosContabeis,
    'page-title': PageTitle
  },
  mixins: [mixinRouterGuardian],
  provide () {
    return {
      ativarAbaAtravesDoModalDeDadosAlterados: this.ativarAbaAtravesDoModalDeDadosAlterados
    }
  },
  inject: ['setActiveSection', 'addShortcut', 'setRootData'],
  data () {
    return {
      smtpVisivel: false,
      infContadorVisivel: false,
      abaUsuarioVisivel: false,
      geraisVisivel: false,
      econtadorVisivel: false,
      relatoriosVisivel: false
    }
  },
  methods: {
    atribuirMetodosModalDadosAlterados () {
      this.setRootData((data) => {
        data.parametrosModalSalvarMudancas.eventoSair = () => {
          this.salvarDadosDaAbaOuSairSemSalvar()
        }

        data.parametrosModalSalvarMudancas.eventoSalvar = () => {
          this.salvarDadosDaAbaOuSairSemSalvar(true)
        }
      })
    },

    async salvarDadosDaAbaOuSairSemSalvar (salvando = false) {
      const rotaAtual = this.$route.path
      const rotaClicada = sessionStorage.getItem('rotaClicadaNoMenuLateral')
      const abaClicada = rotaClicada.split('/')

      switch (rotaAtual) {
        case '/configuracoes/gerais':
          this.metodoParaDirecionarESalvar(salvando, this.$refs.gerais.configurarTrava, abaClicada[2])
          break
        case '/configuracoes/infcontador':
          await this.metodoParaDirecionarESalvar(salvando, this.$refs.infcontador.setConfig, abaClicada[2])
          break
        case '/configuracoes/smtp':
          await this.metodoParaDirecionarESalvar(salvando, this.$refs.smtp.setConfigDoModalDeConfirmacao, abaClicada[2])
          break

        default:
          this.$router.push(rotaClicada)
          break
      }

      this.dadosInalterados()
    },
    async metodoParaDirecionarESalvar (salvando, refFuncaoSalvar, rotaClicada) {
      if (salvando) {
        await refFuncaoSalvar()
      }
      if (sessionStorage.getItem('rotaClicadaNoMenuLateral') != null) {
        this.ativarAbaAtravesDoModalDeDadosAlterados(rotaClicada)
      }
    },
    chamarVerificacaoDeDadosAlterados () {
      this.$root.verificandoDadosAlterados = true

      this.$nextTick(() => {
        this.$root.verificandoDadosAlterados = false
      })
    },
    ativarAbaAtravesDoModalDeDadosAlterados (aba) {
      this.$nextTick(() => {
        this.fecharDemaisAbas()
        switch (aba) {
          case 'smtp':
            this.smtpVisivel = true
            this.$router.replace('/configuracoes/smtp')
            break
          case 'infcontador':
            this.infContadorVisivel = true
            this.$router.replace('/configuracoes/infcontador')
            break
          case 'usuario':
            this.abaUsuarioVisivel = true
            this.$router.replace('/configuracoes/usuario')
            this.setActiveSection('listagemUsuario')
            break
          case 'gerais':
            this.geraisVisivel = true
            this.$router.replace('/configuracoes/gerais')
            break
          case 'integracaoecontador':
            this.econtadorVisivel = true
            this.$router.replace('/configuracoes/integracaoecontador')
            break
          case 'relatorios':
            this.relatoriosVisivel = true
            this.$router.replace('/configuracoes/relatorios')
            break
          default:
            this.verificarSeExisteRotaNaSession()
            break
        }
      })
    },
    ativarAba (aba) {
      this.chamarVerificacaoDeDadosAlterados()
      sessionStorage.setItem('rotaClicadaNoMenuLateral', `/configuracoes/${aba}`)

      this.$nextTick(() => {
        if (this.$root.dadosAlterados === false) {
          this.fecharDemaisAbas()
          switch (aba) {
            case 'smtp':
              this.smtpVisivel = true
              this.$router.replace('/configuracoes/smtp')
              break
            case 'infcontador':
              this.infContadorVisivel = true
              this.$router.replace('/configuracoes/infcontador')
              break
            case 'usuario':
              this.abaUsuarioVisivel = true
              this.$router.replace('/configuracoes/usuario')
              this.setActiveSection('listagemUsuario')
              break
            case 'gerais':
              this.geraisVisivel = true
              this.$router.replace('/configuracoes/gerais')
              break
            case 'integracaoecontador':
              this.econtadorVisivel = true
              this.$router.replace('/configuracoes/integracaoecontador')
              break
            case 'relatorios':
              this.relatoriosVisivel = true
              this.$router.replace('/configuracoes/relatorios')
              break
          }
        }
      })
    },
    atualizarKeyParaReconstruirComponentesQuemUsamEla () {
      this.keyQueTrocaJuntoComARota = this.$route.params.name
    },
    fecharDemaisAbas () {
      this.smtpVisivel = false
      this.infContadorVisivel = false
      this.abaUsuarioVisivel = false
      this.geraisVisivel = false
      this.econtadorVisivel = false
      this.relatoriosVisivel = false
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.$root.mostrarModalDadosAlterados) {
      this.beforeRoute(to, from, next)
      this.setRootData((data) => {
        data.rotaPara = to.fullPath
      })
    } else {
      next()
    }
  },
  mounted () {
    this.atribuirMetodosModalDadosAlterados()
    if (this.$route.params.name) {
      this.activeLink = this.$route.params.name
    }
    this.ativarAba(this.activeLink)
  },
  watch: {
    '$route.params.name' () {
      if (this.$root.dadosAlterados) {
        this.setRootData((data) => {
          data.mostrarModalDadosAlterados = true
        })
      }
      this.ativarAba(this.$route.params.name)
      this.atualizarKeyParaReconstruirComponentesQuemUsamEla()
    }
  }
}
</script>

<style>
</style>
