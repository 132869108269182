<template>
  <ToastAviso
    v-if="exibeAviso"
    :type="statusTeste"
    :title="statusTeste === 'warning' ? titulo : ''"
    @avisoVisivel="exibeAviso = false"
  >
    <div v-if="statusTeste === 'warning'">
      <p>
        Verifique as informações preenchidas e possíveis liberações no seu
        provedor de e-mails.
      </p>
      <br />
      <p style="font-weight: 600">Detalhe:</p>
      <p style="width: 80%">{{ subTitulo }}</p>
    </div>
    <div v-if="statusTeste === 'success'">
      <p>{{ subTitulo }}</p>
    </div>
  </ToastAviso>
  <div class="columns">
    <div class="column is-10">
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="is-size-5 has-text-weight-bold">
            E-mail para envio de relatórios e notificações
          </p>
        </div>
        <div class="column is-12">
          <div class="columns is-multiline">
            <div
              class="column is-2"
              style="padding-bottom: 6px; padding-right: 10px"
            >
              <input
                id="smtp-rad-e-mail-proprio"
                type="radio"
                v-model.number="smtp.stSmtpAlterdata"
                :value="0"
                @click="exibeCampos = true"
              />
              Usar e-mail próprio
            </div>
            <div class="column is-6" style="padding-bottom: 6px">
              <input
                id="smtp-rad-e-mail-alterdata"
                type="radio"
                v-model.number="smtp.stSmtpAlterdata"
                :value="1"
                @click="exibeCampos = false"
              />
              Usar e-mail padrão da Alterdata
            </div>
            <div class="column is-12" style="padding-top: 0px">
              <p class="is-size-6 is-italic">
                O e-mail configurado será usado como remetente em todos as
                mensagens disparadas pelos sistemas, por isso sugerimos a
                utilização de conta própria
              </p>
            </div>
          </div>
        </div>
        <div v-if="exibeCampos" class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-12">
              <input-text
                id="smtp-txt-email"
                name="email"
                label="Endereço de e-mail"
                maxlength="255"
                required
                v-model.trim="smtp.email"
                :message="erros.email"
                :status="getStatus(erros.email)"
                @keypress="onlyEmail"
                @update:erro="erros.email = $event.message"
                @blur="
                  (event) => {
                    handleBlurEmail(event, 'email');
                    handleBlurEmailConfigureSmtp(event);
                  }
                "
              />
            </div>
            <div class="column is-10">
              <input-text
                id="smtp-txt-smtp"
                name="smtp"
                label="SMTP"
                maxlength="255"
                required
                v-model.trim="smtp.smtp"
                :message="erros.smtp"
                :status="getStatus(erros.smtp)"
                @blur="smtp.smtp ? '' : (erros.smtp = 'Informe o SMTP')"
                @keypress="onlyEmail"
                @update:erro="erros.smtp = $event.message"
              />
            </div>
            <div class="column is-2">
              <input-text
                id="smtp-txt-porta"
                name="porta"
                label="Porta"
                required
                v-model="smtp.porta"
                maxlength="255"
                :message="erros.porta"
                :status="getStatus(erros.porta)"
                @blur="smtp.porta ? '' : (erros.porta = 'Informe a porta')"
                @keypress="onlyNumber($event, true)"
                @update:erro="erros.porta = $event.message"
              />
            </div>
            <div class="column is-6">
              <input-text
                id="smtp-txt-conta"
                name="conta"
                label="Conta"
                maxlength="255"
                required
                v-model.trim="smtp.conta"
                :message="erros.conta"
                :status="getStatus(erros.conta)"
                @blur="smtp.conta ? '' : (erros.conta = 'Informe a conta')"
                @keypress="onlyEmail"
                @update:erro="erros.conta = $event.message"
              />
            </div>
            <div class="column is-6">
              <div class="columns">
                <div class="column is-6">
                  <InputPassword
                    id="smtp-senha"
                    label="Senha"
                    name="senha"
                    autocomplete="off"
                    v-model="senha"
                    :required="senhaRequired"
                    :status="getStatus(erros.senha)"
                    :message="erros.senha"
                    :disabledButtonShowPass="senha === ''"
                    :lengthPlaceholder="smtp.lengthPass"
                    @blur="verificarSenhas('senha')"
                    @update:erro="erros.senha = $event.message"
                  />
                  <p
                    class="is-italic"
                    style="font-size: 0.75rem; margin-top: -4px"
                    v-if="!erros.senha"
                  >
                    Senha de acesso ou senha de aplicação
                  </p>
                </div>
                <div class="column is-6">
                  <InputPassword
                    id="smtp-confirmar-senha"
                    name="confirmar-senha"
                    label="Confirmar senha"
                    autocomplete="off"
                    v-model="confirmarSenha"
                    :required="senhaRequired"
                    :message="erros.confirmarSenha"
                    :status="getStatus(erros.confirmarSenha)"
                    :disabledButtonShowPass="confirmarSenha === ''"
                    :lengthPlaceholder="smtp.lengthPass"
                    @blur="verificarSenhas('confirmarSenha')"
                    @update:erro="erros.confirmarSenha = $event.message"
                  />
                </div>
              </div>
            </div>
            <template v-if="!testEmail">
              <div class="column is-8">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="is-flex is-flex-direction-column">
                      <div class="field">
                        <input
                          id="smtp-cbo-requer-autenticacao"
                          type="checkbox"
                          true-value="1"
                          false-value="0"
                          v-model="smtp.stAutenticacao"
                        />
                        Requer autenticação
                      </div>
                      <div class="field">
                        <input
                          id="smtp-cbo-utiliza-criptografia"
                          type="checkbox"
                          true-value="1"
                          false-value="0"
                          v-model="smtp.stCriptografia"
                        />
                        Utiliza criptografia para autenticação segura
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="is-flex is-justify-content-flex-end">
                  <alt-button
                    class="is-light"
                    icon="fa-solid fa-envelope-open"
                    caption="Testar envio de e-mail"
                    @click="handleClickTest"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="column is-4">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="is-flex is-flex-direction-column">
                      <div class="field">
                        <input
                          id="smtp-cbo-requer-autenticacao"
                          type="checkbox"
                          true-value="1"
                          false-value="0"
                          v-model="smtp.stAutenticacao"
                        />
                        Requer autenticação
                      </div>
                      <div class="field">
                        <input
                          id="smtp-cbo-utiliza-criptografia"
                          type="checkbox"
                          true-value="1"
                          false-value="0"
                          v-model="smtp.stCriptografia"
                        />
                        Utiliza criptografia para autenticação segura
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-8">
                <div class="is-flex is-justify-content-flex-end">
                  <div class="ml-2">
                    <alt-button
                      style="position: relative; top: 20px"
                      class="is-light ml-2"
                      icon="fa-solid fa-envelope-open"
                      caption="Testar envio de e-mail"
                      @click="handleClickTest"
                    />
                  </div>
                  <div class="ml-2">
                    <input-text
                      label="E-mail destino"
                      v-model="emailDestino"
                      :message="erros.emailDestino"
                      :status="getStatus(erros.emailDestino)"
                      @focus="erros.emailDestino = ''"
                    />
                  </div>
                  <div class="ml-2">
                    <alt-button
                      style="position: relative; top: 20px"
                      caption="Enviar"
                      icon="paper-plane"
                      :loading="carregando"
                      @click="handleClickTestSend()"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-else class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-4">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="is-flex is-flex-direction-column"></div>
                </div>
              </div>
            </div>
            <div class="column is-8">
              <div
                v-if="!testEmail"
                class="is-flex is-justify-content-flex-end"
              >
                <alt-button
                  class="is-light"
                  icon="fa-solid fa-envelope-open"
                  caption="Testar envio de e-mail"
                  @click="handleClickTest"
                />
              </div>
              <div v-else class="is-flex is-justify-content-flex-end">
                <div class="ml-2">
                  <alt-button
                    style="position: relative; top: 20px"
                    class="is-light ml-2"
                    icon="fa-solid fa-envelope-open"
                    caption="Testar envio de e-mail"
                    @click="handleClickTest"
                  />
                </div>
                <div class="ml-2">
                  <input-text
                    label="E-mail destino"
                    v-model="emailDestino"
                    :message="erros.emailDestino"
                    :status="getStatus(erros.emailDestino)"
                    @focus="erros.emailDestino = ''"
                  />
                </div>
                <div class="ml-2">
                  <alt-button
                    style="position: relative; top: 20px"
                    caption="Enviar"
                    icon="paper-plane"
                    :loading="carregando"
                    @click="handleClickTestSend()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="is-flex is-justify-content-flex-end">
            <alt-button
              id="smtp-btn-gravar"
              class="is-primary"
              caption="Gravar"
              @click="handleClickSave"
            />
              <alt-button @click="aoCancelar"
                id="smtp-btn-cancelar"
                class="is-secondary ml-2"
                caption="Cancelar"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@alterdata/component-vue/InputText'
import Button from '@alterdata/component-vue/Button'
import InputPassword from '../../../components/InputPassword/index.vue'
import Smtp from './model/Smtp'
import smtpDefault from './data/smtpDefault.js'
import { mixin } from '../../../util/mixin'
import { wphdService } from '../../../WphdService'
import ToastAviso from '../../../components/ToastAviso'
import { toast } from 'bulma-toast'
import Toast from '../../../components/ListaToastAviso/Toast/model/Toast'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'

const api = '/api/v1/smtp'

export default {
  components: {
    InputText,
    'alt-button': Button,
    InputPassword,
    ToastAviso
  },
  mixins: [mixinRouterGuardian, mixin],
  inject: ['setRootData', 'rootData', 'ativarAbaAtravesDoModalDeDadosAlterados'],
  data () {
    return {
      smtp: new Smtp(),
      smtpAlterado: new Smtp(),
      smtpDefault,
      erros: {
        email: '',
        smtp: '',
        porta: '',
        conta: '',
        senha: '',
        confirmarSenha: '',
        senhas: '',
        emailDestino: ''
      },
      exibeCampos: true,
      exibeSenha: false,
      tempEmail: '',
      alterarSenha: false,
      senha: '',
      confirmarSenha: '',
      testEmail: false,
      emailDestino: '',
      senhaRequired: false,
      exibeAviso: false,
      titulo: '',
      subTitulo: '',
      carregando: false
    }
  },
  computed: {
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    },
    temErros () {
      for (const chave in this.erros) {
        if (this.erros[chave] !== '') {
          return true
        }
      }
      return false
    }
  },

  watch: {
    verificandoDadosAlteradosRoot (val) {
      if (val) {
        this.verificarModificaoNoItem()
      }
    },
    exibeCampos (val) {
      if (!val) {
        this._limparErros()
      }
    },
    'smtp.email' (val, old) {
      this._verificaModificacao('email', val, old)
    },
    'smtp.smtp' (val, old) {
      this._verificaModificacao('smtp', val, old)
    },
    'smtp.porta' (val, old) {
      this._verificaModificacao('porta', val, old)
    },
    'smtp.conta' (val, old) {
      this._verificaModificacao('conta', val, old)
    },
    senha (val, old) {
      this._verificaModificacao('senha', val, old)
    },
    confirmarSenha (val, old) {
      this._verificaModificacao('confirmarSenha', val, old)
    }
  },
  methods: {

    aoCancelar () {
      this.smtp.stSmtpAlterdata = Number(this.smtp.stSmtpAlterdata)
      this.smtpAlterado.stSmtpAlterdata = Number(this.smtpAlterado.stSmtpAlterdata)
      sessionStorage.removeItem('rotaClicadaNoMenuLateral')
      sessionStorage.setItem('rotaClicadaNoMenuLateral', '/inicio')
      if (!this.verificarModificaoNoItem()) {
        this.$router.push('/inicio')
      }
    },

    verificarModificaoNoItem (LigaModal = true) {
      this.smtpAlterado.stSmtpAlterdata = Number(this.smtpAlterado.stSmtpAlterdata)
      this.smtp.stSmtpAlterdata = Number(this.smtp.stSmtpAlterdata)
      const existeModificacao = !utils.isTotalmenteEqual(this.smtp, this.smtpAlterado)
      if (existeModificacao) {
        this.setRootData((data) => {
          data.dadosAlterados = true
          data.mostrarModalDadosAlterados = true
        })
      } else {
        this.setRootData((data) => {
          data.dadosAlterados = false
        })
      }
      return existeModificacao
    },

    validarCampos () {
      this.handleBlurEmail({ target: { value: this.smtp.email } }, 'email')
      if (this.smtp.porta === '' || this.smtp.porta === null) {
        this.erros.porta = 'Informe a porta'
      }
      if (this.smtp.smtp === '' || this.smtp.smtp === null) {
        this.erros.smtp = 'Informe o SMTP'
      }
      if (this.smtp.conta === '' || this.smtp.conta === null) {
        this.erros.conta = 'Informe a conta'
      }
      this.verificarSenhas('senha')
      this.verificarSenhas('confirmarSenha')
    },
    async getConfig () {
      const obj = await wphdService.get(api)
      Object.assign(this.smtp, obj)
      if (!this.smtp.stSmtpAlterdata) {
        this.smtp.stSmtpAlterdata = '0'
      }
      // eslint-disable-next-line eqeqeq
      this.exibeCampos = this.smtp.stSmtpAlterdata == '0'
      this.smtpAlterado = utils.cloneDeep(this.smtp)
    },
    async setConfig () {
      this.setRootData((data) => {
        data.dadosAlterados = !utils.isTotalmenteEqual(this.smtp, this.smtpAlterado)
      })
      const obj = { stSmtpAlterdata: '' }
      let apiTemp = api
      if (!this.exibeCampos) {
        apiTemp = api + '/st-smtp-alterdata'
        obj.stSmtpAlterdata = Number(this.smtp.stSmtpAlterdata)
      }
      const res = await wphdService.patch(
        apiTemp,
        this.exibeCampos ? this.smtp : obj.stSmtpAlterdata
      )
      if (res.status === 202) {
        this.dadosInalterados()
        this.$router.push('/inicio')
      }
    },
    async setConfigDoModalDeConfirmacao () {
      if (!this.temErros) {
        this.setRootData((data) => {
          data.dadosAlterados = !utils.isTotalmenteEqual(this.smtp, this.smtpAlterado)
        })
        const obj = { stSmtpAlterdata: '' }
        let apiTemp = api
        if (!this.exibeCampos) {
          obj.stSmtpAlterdata = this.smtp.stSmtpAlterdata
          apiTemp = api + '/st-smtp-alterdata'
          obj.stSmtpAlterdata = this.smtp.stSmtpAlterdata
        }
        await wphdService.patch(
          apiTemp,
          this.exibeCampos ? this.smtp : obj.stSmtpAlterdata
        )
      } else {
        this.setRootData((data) => {
          data.mostrarModalDadosAlterados = false
        })
        sessionStorage.removeItem('rotaClicadaNoMenuLateral')
        this.dadosInalterados()
      }
    },
    async handleBlurEmail (event, erro) {
      this.isEmail(event, this.erros, erro, true)
    },
    async handleBlurEmailConfigureSmtp (event) {
      const { value } = event.target
      if (value !== this.tempEmail) {
        if (!this.erros.email) {
          const findSmtp = await this.setProviderSmtp()
          this.setConta(findSmtp)
          if (findSmtp) {
            this.erros.smtp = ''
            this.erros.porta = ''
            this.erros.conta = ''
            this.tempEmail = this.smtp.email
          }
        }
      }
    },
    async setProviderSmtp () {
      const smtp = this.smtp.email.split('@')[1].split('.')[0].toLowerCase()
      let isEmailMicrosoft = false
      const smtpFind = this.smtpDefault.find((st) => {
        if (st.alias instanceof Array) {
          return (
            smtp ===
            st.alias.find((stInterno) => {
              if (stInterno === smtp) {
                isEmailMicrosoft = true
                return true
              }
              return false
            })
          )
        } else {
          return smtp === st.alias
        }
      })
      if (smtpFind) {
        Object.assign(this.smtp, smtpFind)
      }
      smtpFind.isEmailMicrosoft = isEmailMicrosoft
      return smtpFind
    },
    setConta (smtpFind) {
      const conta = smtpFind.isEmailMicrosoft
        ? this.smtp.email
        : this.smtp.email.split('@')[0]
      this.smtp.conta = conta
      this.smtp.stAutenticacao = smtpFind.auth ? '1' : '0'
      this.smtp.stCriptografia = smtpFind.cripto ? '1' : '0'
    },
    _verificarErros () {
      let erro = false
      if (
        this.erros.email ||
        this.erros.smtp ||
        this.erros.porta ||
        this.erros.conta ||
        this.erros.senha
      ) {
        erro = true
      }
      return erro
    },
    async handleClickSave () {
      if (this.exibeCampos) {
        this.validarCampos()
        const result = this.findErrors()
        if (!result) {
          this.setConfig()
        }
      } else {
        await this.setConfig()
      }
    },
    _limparErros () {
      this.erros.email = ''
      this.erros.smtp = ''
      this.erros.porta = ''
      this.erros.conta = ''
      this.erros.senha = ''
      this.erros.confirmarSenha = ''
    },
    findErrors () {
      const erros = [
        'email',
        'smtp',
        'porta',
        'conta',
        'senha',
        'confirmarSenha'
      ]
      let possuiErro = false
      if (this.smtp.stSmtpAlterdata === 0) {
        possuiErro = this.checkErrors(this.erros, erros, [
          () => {
            this._limparErros()
            this.handleBlurEmail({ target: { value: this.smtp.email } }, 'email')
            if (!this.smtp.smtp) {
              this.erros.smtp = 'Informe o SMTP'
            }
            if (!this.smtp.porta) {
              this.erros.porta = 'Informe a porta'
            }
            if (!this.smtp.conta) {
              this.erros.conta = 'Informe a conta'
            }
            this.verificarSenhas()
          }
        ])
      }
      if (possuiErro) {
        if (possuiErro === 'senhas') {
          possuiErro = 'senha'
        }
        document.querySelector(`input[name="${possuiErro}"]`).focus()
        return true
      } else {
        return false
      }
    },
    handleClickAlterarSenha () {
      this.smtp.senha = this.tempSenha
      this.tempSenha = ''
      this.alterarSenha = false
    },
    verificarSenhas (input = '') {
      this._verificaObrigatoriedade()
      if (this.senha && this.confirmarSenha) {
        this._confereSenhas()
      } else {
        this._verificaSenhasVazias(input)
      }
    },
    handleClickTest () {
      this.testEmail = !this.testEmail
    },
    async handleClickTestSend () {
      this.handleBlurEmail(
        { target: { value: this.emailDestino } },
        'emailDestino'
      )
      if (this.emailDestino) {
        if (!this.findErrors()) {
          const url = api + '/teste-de-envio?to=' + this.emailDestino
          this.carregando = true
          try {
            await this._realizaTesteDeEmail(url)
          } catch (error) {
            this.statusTeste = 'error'
          } finally {
            this.carregando = false
          }
        }
      }
    },
    _verificaObrigatoriedade () {
      if (this.senha || this.confirmarSenha) {
        this.senhaRequired = true
      } else {
        if (this.smtp.lengthPass) {
          this.senhaRequired = false
        }
      }
    },
    _confereSenhas () {
      const erroSenhaNaoConfere = 'As senhas não conferem'
      const senhaConfere = this.senha === this.confirmarSenha
      if (!senhaConfere) {
        this.erros.senha = erroSenhaNaoConfere
        this.erros.confirmarSenha = erroSenhaNaoConfere
      } else {
        if (!this.erros.senha.includes('conteúdo do campo')) {
          this.erros.senha = ''
        }
        if (!this.erros.confirmarSenha.includes('conteúdo do campo')) {
          this.erros.confirmarSenha = ''
        }
        this.smtp.senha = this.senha
      }
    },
    _verificaSenhasVazias (input) {
      const erroSenhaVazia = 'Informe a senha'
      if (this.senhaRequired) {
        this._atribuiErroAsSenhasVazias(erroSenhaVazia, input)
      } else {
        if (this.erros.senha === erroSenhaVazia) {
          if (!this.erros.senha.includes('conteúdo do campo')) {
            this.erros.senha = ''
          }
        }
        if (this.erros.confirmarSenha === erroSenhaVazia) {
          if (!this.erros.confirmarSenha.includes('conteúdo do campo')) {
            this.erros.confirmarSenha = ''
          }
        }
      }
    },
    _atribuiErroAsSenhasVazias (erroSenhaVazia, input) {
      if (!this.senha) {
        this.erros.senha = erroSenhaVazia
        if (this.erros.confirmarSenha !== erroSenhaVazia) {
          this.erros.confirmarSenha = ''
        }
      }
      if (!this.confirmarSenha) {
        if (input !== 'senha') {
          this.erros.confirmarSenha = erroSenhaVazia
          if (this.erros.senha !== erroSenhaVazia) {
            this.erros.senha = ''
          }
        } else {
          if (this.erros.confirmarSenha !== erroSenhaVazia) {
            this.erros.confirmarSenha = ''
          }
        }
      }
    },
    _verificaModificacao (input, valor, valorAntigo) {
      this.erros[input] = valor !== valorAntigo ? '' : this.erros[input]
    },
    async _realizaTesteDeEmail (url) {
      const res = await wphdService.post(url, this.smtp, true)
      const resJson = await res.json()
      if (res.status === 400) {
        const { message } = resJson
        this.adicionarNotificacao(
          new Toast({
            tipo: 'warning',
            titulo: 'Problema de comunicação',
            subtitulo: `
            <div>
              <p>
                Verifique as informações preenchidas e possíveis liberações no seu
                provedor de e-mails.
              </p>
              <br />
              <p style="font-weight: 600">Detalhe:</p>
              <p style="width: 80%">${message || 'Erro desconhecido'}.</p>
            </div>
          `
          })
        )
      }
      if (res.status === 200) {
        this.testEmail = false
        this.emailDestino = ''
        const message = `
          <div class="is-flex is-align-items-center">
            <span
              class="fas fa-2x fa-check-circle is-flex is-align-items-center mr-2"
              style="opacity: 0.5"
            >
            </span>
            <span>E-mail enviado com sucesso.</span>
          </div>`
        toast({
          message,
          type: 'is-success',
          duration: 10000,
          dismissible: true
        })
      }
    }
  },
  async beforeMount () {
    this.listagemVisivel = false
    await this.getConfig()
    if (!this.smtp.lengthPass) {
      this.senhaRequired = true
    }
    this.dadosInalterados()
  }
}
</script>

<style scoped>
.is-size-6.is-italic {
  font-size: 0.85rem !important;
  font-weight: 400 !important;
}
</style>
