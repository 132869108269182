import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIEBA extends ValidacaoIE {
  validar () {
    if (this.inscricao.length < 8 || this.inscricao.length > 9) {
      return false
    }

    const ie = this.inscricao.substring(0, this.inscricao.length - 2)
    const digito1 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 2, this.inscricao.length - 1))
    const digito2 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))

    const digito2Calculado = this._calcularDigito(ie)
    const digito1Calculado = this._calcularDigito(ie + digito2Calculado)

    return digito1 === digito1Calculado && digito2 === digito2Calculado
  }

  _retornarModulo (ie = '') {
    const prefixo = Number.parseInt(ie.substring(0, 1))
    const verificacao = [6, 7, 9]

    return verificacao.find(i => i === prefixo) ? 11 : 10
  }

  _retornarPeso (ie = '') {
    if (ie.length === 6) {
      return [7, 6, 5, 4, 3, 2]
    } else {
      if (ie.length === 7) {
        return [8, 7, 6, 5, 4, 3, 2]
      }
      return [9, 8, 7, 6, 5, 4, 3, 2]
    }
  }

  _somarAlgorismos (peso = [0], ie = '') {
    let soma = 0
    for (let i = 0; i < ie.length; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    return soma
  }

  _calcularDigito (ie = '') {
    const modulo = this._retornarModulo(ie)
    const peso = this._retornarPeso(ie)
    const soma = this._somarAlgorismos(peso, ie)

    const mod = soma % modulo

    return (modulo - mod) >= 10 ? 0 : modulo - mod
  }
}
