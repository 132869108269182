import { validadorDeInscricaoFederal } from '../../../util/InscricaoFederal.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { empresaService as service } from '../service/empresa-service'
import InscricaoEstadual from '../../../util/inscricao-estadual/InscricaoEstadual.js'
export default class Empresa {
  constructor (codigo = null) {
    this.numeroCGC = null
    this.cdempresa = codigo
    this.nome = null
    this.nomeFantasia = null
    this.descricaoAtividade = null
    this.empresaAtiva = false
    this.cep = null
    this.logradouro = null
    this.numero = null
    this.enderecoComplemento = null
    this.bairro = null
    this.uf = null
    this.cidade = null
    this.idMunicipio = null
    this.dataInicioAtividade = null
    this.dataFimAtividade = null
    this.inscricaoMunicipal = null
    this.inscricaoEstadual = null
    this.registroOAB = null
    this.valorCapital = null
    this.inscSuframa = null
    this.registroCartorio = null
    this.codigoNire = null
    this.dataExpedicaoNire = null
    this.codigoNaturezaJuridica = null
    this.cnaeFiscal = null
    this.numeroJunta = null
    this.dataRegistroJuntaComercial = null
    this.escritorioDataEntrada = null
    this.escritorioDataSaida = null
    this.tipocalculoImpostoRenda = null
    this.lancamentoAutomatico = false
    this.centroDeCusto = false
    this.dataConversao = null
    this.codigoInscricaoSusep = null
    this.nomeCompleto = null
    this.enderecoCompleto = null
    this.bairroCompleto = null
    this.inicioContabilidade = null
    this.fimContabilidade = null
    this.inicioInformatica = null
    this.fimInformatica = null
    this.observacao = null
    this.tipoPorteEmpresa = null
    this.imglogotipoempresa = null
    this.formatoLogomarca = null
    this.dtconvempresa = null
    this.dtiniservcont = null
    this.dtfimservcont = null
    this.dtiniservinfo = null
    this.dtfimservinfo = null
    this.telefone = null
    this.nomeEmail = null
    this.fax = null
    this.stutilizanmcompleto = null
    this.status = null
    this.codigoCNAE = null
    this.erroLogradouroOuNumero = false
    this.dtNascimento = null
    this.st_dc = null
    // this.enderecoComplemento = null
  }

  static async validar (empresa, cod, editando, validadores) {
    let erros = {}
    erros = await this.validarAbaBasico(empresa, cod, editando, erros)
    erros = this.validarAbaGeral(empresa, erros, editando, validadores)
    erros = this.validarAbaContabil(empresa, erros, validadores)
    erros = this.validarAbaManad(empresa, erros, validadores)
    erros = this.validarAbaSped(empresa, erros)
    erros = this.validarAbaObservacao(empresa, erros)
    return erros
  }

  static async validarAbaBasico (empresa, cod, editando, erros) {
    let erro = false
    let objEmpresa = null
    if (empresa.cdempresa) {
      if (editando) {
        try {
          objEmpresa = await service.get(`/api/v1/empresa/${empresa.cdempresa}`)
        } catch (e) {
          erro = false
        }
      }
    }
    if (empresa.numeroCGC) {
      if (!validadorDeInscricaoFederal.validar(empresa.numeroCGC)) {
        erros.cnpj = 'CNPJ/CPF inválido'
        erro = true
      }
    } else if (!empresa.numeroCGC && editando) {
      erros.cnpj = 'Informe o CNPJ/CPF'
      erro = true
    }

    if (editando) {
      const resultado = await service.get(
        `/api/v1/empresa/verificar-dados-e-social?codigo=${Number(empresa.cdempresa)}`)
      if (resultado && objEmpresa && objEmpresa.numeroCGC !== utils.clearMaskNumber(empresa.numeroCGC)) {
        erros.cnpj = 'Esta empresa já foi enviada para o eSocial, portanto não é possível efetuar a alteração do CNPJ/CPF'
        erro = true
      }
    }
    if (!editando) {
      if (empresa.cdempresa) {
        const zeros = ['0', '00', '000', '0000', '00000']
        if (zeros.includes(empresa.cdempresa)) {
          erros.cdempresa = 'Código inválido'
          erro = true
        }
        try {
          if (objEmpresa) {
            erros.cdempresa = 'O código já existe'
            erro = true
          }
        } catch (error) {
          erros.cdempresa = ''
        }
      } else {
        erros.cdempresa = 'Informe o código'
        erro = true
      }
    }

    if (!empresa.nome) {
      erros.nome = 'Informe o nome da empresa'
      erro = true
    }

    if (!empresa.cep) {
      erros.cep = 'Informe o CEP'
      erro = true
    } else if (empresa.cep.length < 9) {
      erros.cep = 'CEP inválido'
      erro = true
    }

    if (empresa.logradouro === ('undefined, ' + empresa.numero) || empresa.logradouro === undefined + ', ' + null || empresa.logradouro === ', ') {
      erros.logradouro = 'Informe o logradouro'
      erro = true
    }

    if (empresa.bairro === null || empresa.bairro === '') {
      erros.bairro = 'Informe o bairro'
      erro = true
    }
    if (!empresa.uf) {
      erros.uf = 'Informe o UF'
      erro = true
    }
    if (!empresa.cidade) {
      erros.municipioBasico = 'Informe o município'
      erro = true
    }

    if (!empresa.telefone) {
      erros.telefone = 'Informe o telefone'
      erro = true
    } else {
      if (!utils.isTelefone(empresa.telefone)) {
        erros.telefone = 'Telefone inválido'
        erro = true
      }
    }

    if (empresa.nomeEmail) {
      if (!utils.isEmail(empresa.nomeEmail)) {
        erros.email = 'E-mail inválido'
        erro = true
      }
    }

    if (empresa.erroLogradouroOuNumero) {
      erros.endereco = 'Endereço inválido'
      erro = true
    }
    erros.abaBasico = erro
    return erros
  }

  static validarAbaGeral (empresa, erros, editando, validadores) {
    let erro = false
    if (empresa.numeroCGC) {
      const inscricaco = empresa.numeroCGC.replace(/[^\d]+/g, '')
      if (!empresa.valorCapital && inscricaco.length === 14) {
        erros.valorCapital = 'Informe o valor do capital'
        erro = true
      }
    }

    if (!empresa.dataInicioAtividade) {
      erros.dataInicioAtividade = 'Informe a data de início da atividade'
      erro = true
    } else {
      if (new Date(empresa.dataInicioAtividade) > new Date()) {
        erros.dataInicioAtividade = 'Início da atividade não pode ser maior que a data atual'
        erro = true
      } else {
        if (new Date(empresa.dataInicioAtividade) < new Date('1900-01-01')) {
          erros.dataInicioAtividade = 'Informe uma data válida'
          erro = true
        }
      }
    }

    if (empresa.dataFimAtividade && empresa.dataInicioAtividade) {
      if (new Date(empresa.dataInicioAtividade) > new Date(empresa.dataFimAtividade)) {
        erros.dataFimAtividade = 'Início da atividade não pode ser maior que a data final de atividade'
        erro = true
      }
    }

    if (empresa.dataExpedicaoNire) {
      if (new Date(empresa.dataExpedicaoNire) > new Date()) {
        erros.dataExpedicaoNire = 'A data de Expedição do NIRE não pode ser maior que a data atual'
        erro = true
      }
    }

    if (!empresa.codigoNaturezaJuridica) {
      erros.codigoNaturezaJuridica = 'Informe a natureza jurídica'
      erro = true
    }

    if (empresa.uf) {
      if (empresa.inscricaoEstadual) {
        const res = new InscricaoEstadual().validarIE(empresa.uf, empresa.inscricaoEstadual)
        if (!res) {
          erros.inscricaoEstadual = 'Informe uma inscrição estadual válida ou digite ISENTO'
          erro = true
        }
      } else if (!empresa.inscricaoEstadual && editando) {
        erros.inscricaoEstadual = 'Informe uma inscrição estadual válida ou digite ISENTO'
        erro = true
      }
    } else {
      erros.inscricaoEstadual = 'Selecione a UF antes de preencher a inscrição estadual'
      erro = true
    }

    if (validadores.validadorDataJunta === false) {
      erros.dataRegistroJuntaComercial = 'Informe uma data válida'
      erro = true
    }

    if (validadores.validadorDataNire === false) {
      erros.dataExpedicaoNire = 'Informe uma data válida'
      erro = true
    }

    if (empresa.numeroCGC && utils.clearMaskNumber(empresa.numeroCGC).length === 11) {
      if (!empresa.dtNascimento) {
        erros.dtNascimento = 'Informe a data de nascimento do responsável pela empresa'
        erro = true
      } else {
        if (!validadores.validadorDataNascimento) {
          erros.dtNascimento = 'Informe uma data válida'
          erro = true
        }
      }
    }

    if (!empresa.codigoCNAE) {
      erro = true
      erros.cnae = 'Informe o CNAE'
    }

    erros.abaGeral = erro
    return erros
  }

  static validarAbaSped (empresa, erros) {
    const erro = false

    erros.abaSped = erro
    return erros
  }

  static validarAbaContabil (empresa, erros, validadores) {
    let erro = false
    if (empresa.stutilizanmcompleto === 'S' && (!empresa.nomeCompleto || empresa.nomeCompleto.trim() === '') && !empresa.empresaAtiva) {
      erros.stutilizanmcompleto = 'A empresa está configurada para imprimir razão social completa nos Relatórios Legais no Wcont. Favor verifique!'
      erro = true
    }

    if (empresa.dtconvempresa) {
      const dataAtual = new Date()
      if (!utils.isDataValida(new Date(empresa.dtconvempresa))) {
        erros.dataconvempresa = 'Informe uma data válida'
        erro = true
      }
      if (new Date(empresa.dtconvempresa) > dataAtual) {
        erros.dataconvempresa = 'A data de conversão não pode ser maior que a data atual.'
        erro = true
      }
    }

    if (validadores.validadorDataConversao === false) {
      erros.dataconvempresa = 'Informe uma data válida'
      erro = true
    }

    erros.abaContabil = erro
    return erros
  }

  static validarAbaManad (empresa, erros, validadores) {
    let erro = false

    const ERRO_DATA_INICIO = 'A data inicial deve ser menor ou igual a data final'
    const ERRO_DATA_FIM = 'A data final deve ser maior ou igual a data inicial'

    if (empresa.dtiniservcont) {
      if (!utils.isDataValida(new Date(empresa.dtiniservcont))) {
        erros.datainiservcont = 'Informe uma data válida'
        erro = true
      }
      if (empresa.dtfimservcont) {
        if (new Date(empresa.dtiniservcont) > new Date(empresa.dtfimservcont)) {
          erros.datainiservcont = ERRO_DATA_INICIO
          erro = true
        }
      }
    }

    if (validadores.validadorDataIniServCont === false) {
      erros.datainiservcont = 'Informe uma data válida'
      erro = true
    }

    if (empresa.dtfimservcont) {
      if (!utils.isDataValida(new Date(empresa.dtfimservcont))) {
        erros.datafimservcont = 'Informe uma data válida'
        erro = true
      }
      if (new Date(empresa.dtfimservcont) < new Date(empresa.dtiniservcont)) {
        erros.datafimservcont = ERRO_DATA_FIM
        erro = true
      }
      if (!empresa.dtiniservcont || empresa.dtiniservcont === '') {
        erros.datainiservcont = 'Informe uma data de início válida'
        erro = true
      }
    }

    if (validadores.validadorDataFimServCont === false) {
      erros.datafimservcont = 'Informe uma data válida'
      erro = true
    }

    if (empresa.dtiniservinfo) {
      if (!utils.isDataValida(new Date(empresa.dtiniservinfo))) {
        erros.datainiservinfo = 'Informe uma data válida'
        erro = true
      }
      if (empresa.dtfimservinfo) {
        if (new Date(empresa.dtiniservinfo) > new Date(empresa.dtfimservinfo)) {
          erros.datainiservinfo = ERRO_DATA_INICIO
          erro = true
        }
      }
    }

    if (validadores.validadorDataIniServInfo === false) {
      erros.datainiservinfo = 'Informe uma data válida'
      erro = true
    }

    if (empresa.dtfimservinfo) {
      if (!utils.isDataValida(new Date(empresa.dtfimservinfo))) {
        erros.datafimservinfo = 'Informe uma data válida'
        erro = true
      }
      if (new Date(empresa.dtfimservinfo) < new Date(empresa.dtiniservinfo)) {
        erros.datafimservinfo = ERRO_DATA_FIM
        erro = true
      }
      if (!empresa.dtiniservinfo) {
        erros.datainiservinfo = 'Informe uma data de início válida'
        erro = true
      }
    }

    if (validadores.validadorDataFimServInfo === false) {
      erros.datafimservinfo = 'Informe uma data válida'
      erro = true
    }

    erros.abaManad = erro
    return erros
  }

  static validarAbaObservacao (empresa, erros) {
    let erro = false

    if (empresa.observacao && empresa.observacao.length > 1000) {
      erros.observacao = 'Limite de caracteres excedido para observação, o limite é de 1000 caracteres.'
      erro = true
    }

    erros.abaObservacao = erro
    return erros
  }
}
