import { service } from '../service'

const ROTA = '/api/v1/integracao-e-contador'

export const integracaoeContador = {
  constructor () {
    this.socemps = []
  },

  async integrar (idEmpresa) {
    try {
      await this._integrarBureau()
      await this._integrarEmpresa(idEmpresa)
      await this._integrarPorSociosEmpresa(idEmpresa)
      return { retorno: true, msg: 'sucesso' }
    } catch (error) {
      return { retorno: false, msg: error.message }
    }
  },

  async _integrarBureau () {
    this.apiResposta = await service.post(`${ROTA}/integrar-bureau-econtador`)
  },
  async _integrarEmpresa (idEmpresa) {
    this.apiResposta = await service.post(`${ROTA}/integrar-empresa-econtador/${idEmpresa}`)
  },
  async integrarPorSocio (obj) {
    const sociosNaoIntegrados = []
    for (const vinculo of obj) {
      try {
        service.post(`${ROTA}/cadastrar-vinculo-socio-empresa-econtador-por-socio/${vinculo.codigoSocio}/empresa/${vinculo.idEmpresaSocio}`)
      } catch (error) {
        sociosNaoIntegrados.push({ socio: vinculo.codigoSocio, msg: error.message })
      }
    }
    if (sociosNaoIntegrados.length > 0) {
      return sociosNaoIntegrados
    }
  },
  async desintegrarPorSocio (obj) {
    // faz parte da PON-991 remover este console quando estiver ok
    console.log('Desintegrar Sócio -> ' + obj.codigoSocio + ' da empresa  -> ' + obj.idEmpresaSocio)
    service.post(`${ROTA}/remover-vinculo-socio-empresa-econtador-por-socio/${obj.codigoSocio}/empresa/${obj.idEmpresaSocio}`)
  },

  async _integrarPorSociosEmpresa (idEmpresa) {
    await service.post(`${ROTA}/integrar-socio-econtador/${idEmpresa}`)
  },

  async desintegrar (idEmpresa) {
    try {
      this.apiResposta = await service.post(`${ROTA}/remover-integracao-empresa-econtador/${idEmpresa}`)
      // Comentei, pois aparentemente segundo o processo, não precisa remover o vínculo de sócio ao remover tirar a integração de uma empresa.
      // await this._carregarSocios(idEmpresa)
      // this.socemps.forEach(async cdSocio => {
      //   this.apiResposta = await service.post(`/api/v1/integracao-e-contador/remover-vinculo-socio-empresa-econtador-por-socio/${cdSocio.idsocio}/empresa/${idEmpresa}`, obj)
      // })
      return true
    } catch (error) {
      console.log('--> Erro:', error)
      return false
    }
  },

  async podeIntegrar () {
    try {
      return await service.post(`${ROTA}/cliente-pode-integrar`)
        .then(res => res.json())
        .then(result => {
          console.log(result.podeIntegrar)
          return result.podeIntegrar
        })
    } catch (error) {
      throw new Error(error.message)
    }
  },

  async _carregarSocios (paramEmpresa) {
    let retornoSocios = []
    retornoSocios = await service.get(
      `/api/v1/socemp/empresa/${paramEmpresa}`
    )
    this.socemps = retornoSocios.content
  }
}
