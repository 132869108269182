class InscricaoFederal {
  constructor () {
    this.CNPJS_INVALIDOS = [
      '00000000000000', '11111111111111', '22222222222222', '33333333333333', '44444444444444',
      '55555555555555', '66666666666666', '77777777777777', '88888888888888', '99999999999999'
    ]

    this.CPFS_INVALIDOS = [
      '00000000000', '11111111111', '22222222222', '33333333333', '44444444444',
      '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'
    ]
  }

  validar (inscricaoFederal) {
    let valid = false
    if (inscricaoFederal) {
      inscricaoFederal = inscricaoFederal.replace(/[^\d]+/g, '')
      if (inscricaoFederal.length === 11) {
        valid = this.validarCPF(inscricaoFederal)
      } else if (inscricaoFederal.length === 14) {
        valid = this.validarCNPJ(inscricaoFederal)
      }
    }
    return valid
  }

  validarCNPJ (cnpj) {
    if (!cnpj) {
      return false
    }

    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (cnpj.length !== 14) {
      return false
    }

    if (this.CNPJS_INVALIDOS.includes(cnpj)) {
      return false
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    const digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) {
        pos = 9
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado.toString() !== digitos.charAt(0)) {
      return false
    }

    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2) {
        pos = 9
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado.toString() !== digitos.charAt(1)) {
      return false
    }

    return true
  }

  validarCPF (cpf) {
    if (!cpf) {
      return false
    }

    cpf = cpf.replace(/[^\d]+/g, '')

    if (cpf.length !== 11) {
      return false
    }

    if (this.CPFS_INVALIDOS.includes(cpf)) {
      return false
    }

    let add = 0
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i)
    }
    let rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) {
      rev = 0
    }
    if (rev !== parseInt(cpf.charAt(9))) {
      return false
    }
    add = 0
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i)
    }
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) {
      rev = 0
    }
    if (rev !== parseInt(cpf.charAt(10))) {
      return false
    }
    return true
  }

  aplicarMascaraCnpj (cnpj) {
    if (!cnpj) {
      return ''
    }
    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2')
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2')
    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2')
    return cnpj
  }

  aplicarMascaraCpf (cpf) {
    if (!cpf) {
      return ''
    }
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2')
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return cpf
  }
}

export const validadorDeInscricaoFederal = new InscricaoFederal()
