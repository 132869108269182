<template>
  <div class="pagination">
    <button id="botaoprimeiro" class="item first"  @click="_primeira">
      Primeira
    </button>
    <button id="botaoanterior"
      class="item prev"
      @click="_anterior(this.pagina - 1)"
    >
      « Anterior
    </button>
    <select class="item" v-on:change="changePage(parseInt($event.target.value))" v-model="this.pagina">
      <option v-for="(page) in pages" :key="page">{{page}}</option>
    </select>
    <button class="item" style="border-left: hidden;">{{this.textoItensExibindo}}</button>
    <button id="botaoproximo" class="item next" @click="_proxima(this.pagina + 1)">
      Próxima »
    </button>
    <button id="botaoultimo" class="item last" @click="_ultima">
      Última
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: [String, Number],
      required: true,
      default: 0
    },
    limit: {
      type: [String, Number],
      default: 20
    },
    pQuantidadeDeItemsExibindo: {
      type: [String, Number],
      required: true,
      default: 1
    },
    idDivUtilizada: {
      type: [String]
    },
    pPages: {
      type: [String, Number]
    },
    lastSearch: {
      type: Number
    }
  },
  computed: {
    pages () {
      if (this.pPages) {
        return Array.from(Array(this.pPages).keys(), (i) => i + 1)
      } else {
        const qty = Math.ceil(this.total / this.limit)
        if (qty <= 1) return [1]
        return Array.from(Array(qty).keys(), (i) => i + 1)
      }
    }
  },
  data () {
    return {
      pagina: 1,
      anterior: 1,
      proxima: 1,
      textoItensExibindo: '',
      atributoBotaoPrimeiro: '',
      atributoBotaoAnterior: '',
      atributoBotaoProximo: '',
      atributoBotaoUltimo: ''
    }
  },
  methods: {
    changePage (offset) {
      if (this.pages.length > 1) {
        this.pagina = offset
        this.$emit('change-page', offset - 1)
        this._defineEstadoPaginacao()
      }
    },

    _primeira () {
      if (this.pagina > 1) {
        this.changePage(0)
        this.pagina = 1
      }
    },

    _ultima () {
      if (this.pPages) {
        if (this.pagina < this.pPages) {
          this.changePage(this.pPages)
          this.pagina = this.pPages
        }
      } else {
        if (this.pagina < Math.ceil(this.total / this.limit)) {
          this.changePage(Math.ceil(this.total / this.limit))
          this.pagina = Math.ceil(this.total / this.limit)
        }
      }
    },

    _proxima (pagina) {
      if (this.pPages) {
        if (this.pagina < this.pPages) {
          this.changePage(pagina)
        }
      } else {
        if (this.pagina < Math.ceil(this.total / this.limit)) {
          this.changePage(pagina)
        }
      }
    },

    _anterior (pagina) {
      if (this.pagina > 1) {
        this.changePage(pagina)
      }
    },

    _verificarPaginaAtual () {
      if (this.pPages) {
        return this.pagina >= this.pPages
      }
      return this.pagina >= Math.ceil(this.total / this.limit)
    },

    _defineUltimoHabilitado () {
      let botao = document.querySelector('#' + this.idDivUtilizada)
      if (botao) {
        botao = botao.querySelector('#botaoultimo')
        if (botao) {
          if (this._verificarPaginaAtual()) {
            botao.setAttribute('class', 'desabilitado')
          } else {
            botao.setAttribute('class', this.atributoBotaoUltimo)
          }
        }
      }
    },

    _defineProximoHabilitado () {
      let botao = document.querySelector('#' + this.idDivUtilizada)
      if (botao) {
        botao = botao.querySelector('#botaoproximo')
        if (botao) {
          if (this._verificarPaginaAtual()) {
            botao.setAttribute('class', 'desabilitado')
          } else {
            botao.setAttribute('class', this.atributoBotaoProximo)
          }
        }
      }
    },

    _defineAnteriorHabilitado () {
      let botao = document.querySelector('#' + this.idDivUtilizada)
      if (botao) {
        botao = botao.querySelector('#botaoanterior')
        if (botao) {
          if (!(this.pagina > 1)) {
            botao.setAttribute('class', 'desabilitado')
          } else {
            botao.setAttribute('class', this.atributoBotaoAnterior)
          }
        }
      }
    },

    _definePrimeiroHabilitado () {
      let botao = document.querySelector('#' + this.idDivUtilizada)
      if (botao) {
        botao = botao.querySelector('#botaoprimeiro')
        if (botao) {
          if (!(this.pagina > 1)) {
            botao.setAttribute('class', 'desabilitado')
          } else {
            botao.setAttribute('class', this.atributoBotaoPrimeiro)
          }
        }
      }
    },

    _defineEstadoPaginacao () {
      this._defineUltimoHabilitado()
      this._defineProximoHabilitado()
      this._defineAnteriorHabilitado()
      this._definePrimeiroHabilitado()
    }
  },
  watch: {
    pQuantidadeDeItemsExibindo: {
      immediate: true,
      handler () {
        this.textoItensExibindo = ((this.pagina - 1) * 20) + 1 + ' a ' + (((this.pagina - 1) * 20) + this.pQuantidadeDeItemsExibindo)
        this._defineEstadoPaginacao()
      }
    },
    pagina: {
      immediate: true,
      handler () {
        this.textoItensExibindo = ((this.pagina - 1) * 20) + 1 + ' a ' + (((this.pagina - 1) * 20) + this.pQuantidadeDeItemsExibindo)
        this._defineEstadoPaginacao()
      }
    },
    total () {
      this.$nextTick(() => this._primeira())
    },
    lastSearch () {
      this.$nextTick(() => this._primeira())
    }
  },
  mounted: function () {
    this.atributoBotaoPrimeiro = document.querySelector('#' + this.idDivUtilizada).querySelector('#botaoprimeiro').getAttribute('class')
    this.atributoBotaoAnterior = document.querySelector('#' + this.idDivUtilizada).querySelector('#botaoanterior').getAttribute('class')
    this.atributoBotaoProximo = document.querySelector('#' + this.idDivUtilizada).querySelector('#botaoproximo').getAttribute('class')
    this.atributoBotaoUltimo = document.querySelector('#' + this.idDivUtilizada).querySelector('#botaoultimo').getAttribute('class')
    this._defineEstadoPaginacao()
  }
}
</script>

<style lang="scss" scoped>
$light-grey: #cccccc;
$dark-grey: #333333;
$orange: #f90;
$dark-orange: #f63;
.pagination {
  display: flex;
  justify-content: center;
  .item {
    padding: 0.5rem 0.75rem;
    border: 1px solid $light-grey;
    cursor: pointer;
    background-color: white;
    color: #c22743;
    font-weight: bold;
    height:36px;
    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &:hover {
      background-color: $light-grey;
      border-color: lighten($dark-grey, 50%);
      z-index: 3;
    }
    &.current {
      cursor: default;
      color: white;
      background-color: $orange;
      border-color: $dark-orange;
      z-index: 2;
    }
    + .item {
      margin-left: -1px;
      margin-right: 0;
    }
  }

  .desabilitado {
    padding: 0.5rem 0.75rem;
    border: 1px solid $light-grey;
    background-color: white;
    color: #b3b1b3;
    font-weight: bold;
    height: 36px;
  }
}
</style>
