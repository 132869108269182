import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIEBasePrefixo extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 9 || !this.verificarPrefixoIe()) {
      return false
    }

    const peso = [9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)
    const digito = this.inscricao.substring(this.inscricao.length - 1)
    let soma = 0

    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    const resultado = Number.parseInt((soma % 11) <= 1 ? 0 : 11 - (soma % 11))

    return resultado === Number.parseInt(digito)
  }

  verificarPrefixoIe () {
    return false
  }
}
