<template>
  <div class="columns">
    <div class="column is-10">
      <div class="columns is-multiline">
        <div class="field column is-12">
          <p class="is-size-6 has-text-weight-bold">Identificação</p>
        </div>
        <div class="field column is-1">
          <label>Pessoa</label><br />
          <label
            ><strong>{{ this.isCpfCnpj }}</strong>
          </label>
        </div>

        <div class="column is-5" style="padding-bottom: 6px">
          <input-text
            id=" estabelecimento"
            v-model="infContador.estabelecimento"
            label="Razão social"
            disabled
          />
        </div>
        <div class="column is-3" style="padding-bottom: 6px">
          <input-text
            id=" cpf"
            v-model="infContador.cpfcgc"
            :label="this.tipoPessoa ? 'CPF' : 'CNPJ'"
            disabled
          />
        </div>

        <div class="column is-6">
          <input-text
            id="endereco"
            v-model="infContador.endereco"
            label="Logradouro"
            disabled
          />
        </div>

        <div class="column is-2" style="padding-bottom: 6px">
          <input-text
            id="bairro"
            v-model="infContador.bairro"
            label="Bairro"
            disabled
          />
        </div>
        <div class="column is-1" style="width: 7%">
          <input-text id=" UF" v-model="infContador.uf" label="UF" disabled />
        </div>
        <div class="column is-3" style="padding-bottom: 6px">
          <input-text
            id=" UF"
            v-model="codIbgeEMunicipio"
            label="Município"
            disabled
          />
        </div>
        <div class="column is-12" style="padding-bottom: 6px">
          <p class="is-size-6 has-text-weight-bold">Responsável</p>
        </div>
        <div id="divresponsavel" class="column is-4" style="padding-bottom: 6px">
          <input-text
            id="responsavel"
            v-model="infContador.responsavel"
            required
            label="Nome"
            maxlength="50"
            :message="this.erros.responsavel"
            :status="getStatus(erros.responsavel)"
            @update:erro="erros.responsavel = $event.message"
            @blur="
              this.erros.responsavel ? (this.erros.responsavel = '') : false
            "
          />
        </div>

        <div class="column is-2" style="padding-bottom: 6px">
          <validador-cpf-cnpj
            id="cpf"
            v-model="this.basico.cpf"
            label="CPF"
            type="CPF"
            required
            placeholder="000.000.000-00"
            v-maska="'###.###.###-##'"
            :message="this.erros.cpf"
            :status="this.getStatus(this.erros.cpf)"
            @update:erro="erros.cpf = $event.message"
            @input="this.erros.cpf = ''"
          />
        </div>
        <div class="column is-2" style="padding-bottom: 6px">
          <input-text
            id="creci"
            v-model="infContador.creci"
            label="CRC"
            maxlength="20"
            required
            :message="this.erros.crc"
            :status="this.getStatus(this.erros.crc)"
            @blur="this.erros.crc ? (this.erros.crc = '') : false"
          />
        </div>
        <div class="column is-2" style="padding-bottom: 6px">
          <input-data
            id="nascimento"
            label="Nascimento"
            v-model="this.basico.datanascimento"
            :modelValue="this.basico.datanascimento"
            @update:erro="this.erros.datanascimento = $event"
            @update:valid="this.validadorData = $event"
            :naoPermiteDataFutura="true"
            :status="this.getStatus(this.erros.datanascimento)"
            :message="erros.datanascimento"
            :key="key"
            required
          />
        </div>
        <div class="column is-2">
          <input-text
            id="telefonecelular"
            v-model="infContador.telefonecelular"
            label="Celular"
            maxlength="11"
            :message="this.erros.celular"
            :status="this.getStatus(this.erros.celular)"
            @update:erro="erros.celular = $event.message"
            @blur="this.erros.celular ? (this.erros.celular = '') : false"
          />
        </div>
        <!-- Agrupamento Outras informações -->
        <div class="column is-12" style="padding-bottom: 6px">
          <p class="is-size-6 has-text-weight-bold">Outras informações</p>
        </div>
        <div class="column is-3" style="padding-bottom: 6px">
          <input-text
            id="cep"
            v-model="this.basico.cep"
            label="CEP"
            maxlength="9"
            required
            v-maska="'#####-###'"
            :message="this.erros.cep"
            :status="this.getStatus(this.erros.cep)"
            @update:erro="erros.cep = $event.message"
            @keypress="this.onlyNumber()"
            @blur="this.erros.cep ? (this.erros.cep = '') : false"
          />
        </div>
        <div class="column is-3">
          <input-text
            id="telefonePrincipal"
            label="Telefone principal"
            maxlength="11"
            required
            type="text"
            v-model="this.basico.telefone"
            :message="erros.telefone"
            :status="this.getStatus(this.erros.telefone)"
            @update:erro="this.erros.telefone = $event.message"
            @keypress="this.onlyNumber()"
            @blur="this.erros.telefone ? (this.erros.telefone = '') : false"
          />
        </div>
        <div class="column is-3" style="padding-bottom: 6px">
          <input-text
            id="telContato"
            v-model="infContador.fax"
            label="Telefone adicional"
            maxlength="11"
            :message="this.erros.telefoneadicional"
            :status="this.getStatus(this.erros.telefoneadicional)"
            @update:erro="erros.telefoneadicional = $event.message"
            @keypress="this.onlyNumber()"
            @blur="
              this.erros.telefoneadicional
                ? (this.erros.telefoneadicional = '')
                : false
            "
          />
        </div>

        <div class="column is-3" style="padding-bottom: 6px">
          <input-text
            id="telfax"
            v-model="infContador.inscricaomunicipal"
            label="Inscrição municipal"
            maxlength="15"
            :message="this.erros.inscricao"
            :status="this.getStatus(this.erros.inscricao)"
            @update:erro="erros.inscricao = $event.message"
            @blur="this.erros.inscricao ? (this.erros.inscricao = '') : false"
          />
        </div>

        <div class="column is-5" style="padding-bottom: 6px">
          <input-text
            id="email"
            type="text"
            label="E-mail"
            :maxlength="50"
            v-model="this.basico.email"
            :value="this.basico.email"
            :message="erros.email"
            :status="this.getStatus(this.erros.email)"
            :modelValue="this.basico.email"
            @update:erro="this.erros.email = $event.message"
            @update:modelValue="this.basico.email = $event"
            @keypress="this.onlyEmail()"
            @blur="this.erros.email ? (this.erros.email = '') : false"
          />
        </div>

        <div class="column is-12" style="padding-bottom: 6px">
          <div class="is-flex is-flex-direction-column">
            <div class="field">
              <label class="checkbox">
                <input type="checkbox" v-model="infContador.optantesimples" />
                Optante do simples
              </label>
            </div>
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="infContador.incentivadorcultural"
              />
              Incentivador cultural
            </label>
          </div>
        </div>
      </div>
      <div class="is-flex is-justify-content-flex-end">
        <alt-button
          class="button is-primary"
          @click="this.setConfig()"
          caption="Gravar"
        >
          Gravar
        </alt-button>
          <alt-button
            id="btn-cancelar"
            class="is-secondary ml-2"
            caption="Cancelar"
            @click="aoCancelar"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin.js'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao.js'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import InfContador from './model/inf-contador.js'
import InputText from '@alterdata/component-vue/InputText'
import { validadorDeInscricaoFederal } from '@packonline/packonline-reutilizaveis-frontend/Utils/InscricaoFederal.js'
import { wphdService } from '@/WphdService.js'
import Button from '@alterdata/component-vue/Button'
import ValidadorCpfCnpj from '@/components/ValidadorCpfCnpj/index.vue'
import { maska } from 'maska'
import InputDate from '@alterdata/component-vue/InputDate/index.vue'

const ERROS = {
  cpf: '',
  cep: '',
  email: '',
  telefone: '',
  responsavel: '',
  crc: '',
  datanascimento: '',
  celular: '',
  telefoneadicional: '',
  inscricao: ''
}

export default {
  mixins: [mixin, mixinRouterGuardian, mixinModalSaida],
  emits: ['atualizar-item'],
  components: {
    InputText,

    'validador-cpf-cnpj': ValidadorCpfCnpj,
    'alt-button': Button,
    'input-data': InputDate
  },
  directives: {
    maska
  },
  props: {
    errosValidacao: Object,
    editando: Boolean
  },
  inject: ['setRootData', 'rootData', 'ativarAbaAtravesDoModalDeDadosAlterados'],
  data () {
    return {
      infContador: new InfContador(),
      infContadorOriginal: new InfContador(),
      infoContadorVisivel: false,
      isCpfCnpj: '',
      codIbgeEMunicipio: '',
      basico: {
        cpf: null,
        cep: null,
        email: null,
        telefone: null,
        datanascimento: null
      },
      basicoOriginal: {
        cpf: null,
        cep: null,
        email: null,
        telefone: null,
        datanascimento: null
      },
      key: 0,
      erros: ERROS,
      tipoPessoa: '',
      listagemVisivel: false
    }
  },
  cpfOuCnpj: {
    immediate: true,
    handler: function (val, old) {
      if (val) {
        this.erros.inscricaoCpfCnpj = ''
      }
    }
  },
  mounted () {
    this.focarNoPrimeiroInput('divresponsavel')
  },
  methods: {
    async getConfig () {
      await wphdService
        .get('/api/v1/infcontador')
        .then((obj) => {
          Object.assign(this.infContador, obj)
          this.codIbgeEMunicipio =
            utils.ufibge(this.infContador.uf) + this.infContador.cdmunicipio
          this.infContador.cpfcgc = this._formatarCPFCNPJ(
            this.infContador.cpfcgc
          )
          if (this.infContador.cpf !== null) {
            this.isCpfCnpj = this._isCPF_CNPJ(this.infContador.cpfcgc)
            this.basico.cpf = this._formatarCPFCNPJ(
              this.infContador.cpfresponsavel
            )
          }
          if (this.infContador.cep !== null) {
            this.basico.cep = utils.formatarCEP(this.infContador.cep)
          }
          this.basico.email = this.infContador.email
          this.basico.telefone = this.infContador.telefone
          this.codIbgeEMunicipio =
            this.codIbgeEMunicipio + ' ' + this.infContador.cidade
          this.infContador.optantesimples =
            this.infContador.optantesimples === '1'
          this.infContador.incentivadorcultural =
            this.infContador.incentivadorcultural === '1'
          if (this.infContador.datanascimento !== null) {
            this.basico.datanascimento = this.converteDataComponente(
              this.infContador.datanascimento
            )
          }
          this.infContadorOriginal = utils.cloneDeep(this.infContador)
          Object.assign(this.basicoOriginal, this.basico)
        })
        .catch((erro) => {
          console.log('----->', erro)
        })
    },
    converteDataComponente (strDate) {
      try {
        const dataLatin2American = strDate.split('/').reverse()
        const dataConvertida = new Date(
          dataLatin2American[0],
          dataLatin2American[1] - 1,
          dataLatin2American[2]
        )
        this.basico.datanascimento = dataConvertida.toISOString().substr(0, 10)
        return dataConvertida.toISOString().substr(0, 10)
      } catch {
        this.erros.nascimento = 'Informe uma data válida'
      }
    },
    async setConfig () {
      this.LimpaErros()
      this.validaCampos()
      if (!this._checkErr()) {
        this.infContador.cep = this.basico.cep.replace('-', '')
        this.infContador.email = this.basico.email
        this.infContador.telefone = this.basico.telefone
        this.infContador.cpfresponsavel = this.basico.cpf.replace(
          /[^0-9]+/g,
          ''
        )
        this.infContador.optantesimples = this.infContador.optantesimples
          ? '1'
          : '0'
        this.infContador.incentivadorcultural = this.infContador
          .incentivadorcultural
          ? '1'
          : '0'
        this.infContador.datanascimento = this.basico.datanascimento
          .split('-')
          .reverse()
          .join('/')
        const res = await wphdService.patch(
          '/api/v1/infcontador',
          this.infContador
        )
        if (res.status === 200) {
          this.dadosInalterados()
          this.redirecionarAposConfigurar()
        } else {
          sessionStorage.removeItem('rotaClicadaNoMenuLateral')
        }
      } else {
        sessionStorage.removeItem('rotaClicadaNoMenuLateral')
      }
    },
    redirecionarAposConfigurar () {
      const rotaAtual = this.$route.path
      const rotaClicada = sessionStorage.getItem('rotaClicadaNoMenuLateral')
      if (rotaAtual !== rotaClicada) {
        if (!this.verificarSeExisteRotaNaSession()) {
          this.$router.push('/inicio')
        } else {
          const abaClicada = rotaClicada.split('/')
          this.ativarAbaAtravesDoModalDeDadosAlterados(abaClicada)
        }
      } else {
        this.$router.push('/inicio')
      }
    },
    validaCampos () {
      if (this.basico.cep === '') {
        this.erros.cep = 'Informe o CEP'
      }
      if (!utils.isEmail(this.basico.email)) {
        this.erros.email = 'E-mail inválido'
      }
      if (this.basico.telefone === '') {
        this.erros.telefone = 'Informe o telefone'
      }
      if (this.infContador.responsavel === '') {
        this.erros.responsavel = 'Informe o nome'
      }
      if (this.basico.cpf === '') {
        this.erros.cpf = 'Informe o CPF'
      }

      if (this._checarSeInscricaoECPF(this.basico.cpfresponsavel)) {
        this.erros.cpf = 'CPF inválido'
      }
      if (this.infContador.creci === '') {
        this.erros.crc = 'Informe o CRC'
      }
      if (!utils.isDataDigitadaValida(this.basico.datanascimento)) {
        this.erros.datanascimento = 'Informe uma data válida'
      }
      if (!utils.isDataDigitadaValida(this.basico.datanascimento)) {
        this.erros.datanascimento = 'Informe uma data válida'
      }
    },
    _checkErr () {
      let err = false
      if (
        this.erros.cep ||
        this.erros.email ||
        this.erros.telefone ||
        this.erros.responsavel ||
        this.erros.cpf ||
        this.erros.crc ||
        this.erros.datanascimento ||
        this.erros.celular ||
        this.erros.telefoneadicional ||
        this.erros.inscricao
      ) {
        err = true
      }
      return err
    },
    onlyNumber (e) {
      return utils.onlyNumber(e)
    },
    _checarCEP () {
      if (this.cep) {
        const res = this.cep.replace('-', '').length === 8
        if (res) {
          this.erros.cep = ''
        }
        return res
      } else {
        return false
      }
    },
    _checarSeInscricaoECPF () {
      if (this.objCpf) {
        const res = this.objCpf.replace(/[^\d]+/g, '').length === 11
        if (res) {
          this.erros.cpf = ''
        }
        return res
      } else {
        return false
      }
    },

    _isCPF_CNPJ (valor) {
      const inscricaoFederal = valor ? valor.replace(/[^\d]+/g, '') : ''
      if (inscricaoFederal.length === 11) {
        this.tipoPessoa = true
        return 'Física'
      } else {
        this.tipoPessoa = false
        return 'Jurídica'
      }
    },
    _formatarCPFCNPJ (valor) {
      if (valor) {
        const inscricaoFederal = valor.replace(/[^\d]+/g, '') || ''
        if (inscricaoFederal.length === 11) {
          return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricaoFederal)
        } else if (inscricaoFederal.length === 14) {
          return validadorDeInscricaoFederal.aplicarMascaraCnpj(
            inscricaoFederal
          )
        }
      }
    },
    fechar () {
      this.LimpaErros()
      this.$emit('on-cancelar')
    },
    _retornarCPFCNPJFormatado (inscricao) {
      if (inscricao) {
        inscricao = inscricao.replace(/[^0-9]+/g, '')
        if (inscricao.length === 14) {
          return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricao)
        }
        if (inscricao.length === 11) {
          return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricao)
        }
      } else {
        return null
      }
    },
    LimpaErros () {
      this.erros.cpfresponsavel = ''
      this.erros.cep = ''
      this.erros.email = ''
      this.erros.telefone = ''
      this.erros.responsavel = ''
      this.erros.crc = ''
      this.erros.datanascimento = ''
      this.erros.celular = ''
      this.erros.telefoneadicional = ''
      this.erros.inscricao = ''
    },
    onlyEmail (e) {
      return utils.emailFormat(e)
    },
    isEmail (e) {
      const email = utils.isEmail(e.target.value)
      if (!email) {
        if (!this.erros.email) {
          this.erros.email = 'E-mail inválido'
        }
      }
    },
    verificarModificaoNoItem () {
      const basicoOriginal = {}
      const basico = {}
      Object.assign(basicoOriginal, this.basicoOriginal)
      Object.assign(basico, this.basico)

      this.formatarObjBasicoParaComparacao(basicoOriginal)
      this.formatarObjBasicoParaComparacao(basico)

      this.formatarObjContadorParaComparacao(this.infContador)
      this.formatarObjContadorParaComparacao(this.infContadorOriginal)

      const existeModificacaoBasico = !utils.isEqual(basico, basicoOriginal)
      const existeModificacao = !utils.isEqual(this.infContador, this.infContadorOriginal)

      if (existeModificacao || existeModificacaoBasico) {
        this.setRootData((data) => {
          data.dadosAlterados = true
          data.mostrarModalDadosAlterados = true
        })
      } else {
        this.setRootData((data) => {
          data.dadosAlterados = false
        })
      }
      return existeModificacao
    },
    formatarObjBasicoParaComparacao (objeto) {
      if (objeto.cep) {
        objeto.cep = objeto.cep.replace('-', '')
      }
      if (objeto.datanascimento) {
        objeto.datanascimento = objeto.datanascimento
          .split('-')
          .reverse()
          .join('/')
      }
      if (!objeto.cpf) {
        objeto.cpf = null
      }
      return objeto
    },
    formatarObjContadorParaComparacao (objeto) {
      if (!objeto.responsavel) {
        objeto.responsavel = ''
      }
    },
    aoCancelar () {
      sessionStorage.removeItem('rotaClicadaNoMenuLateral')
      sessionStorage.setItem('rotaClicadaNoMenuLateral', '/inicio')
      if (!this.verificarModificaoNoItem()) {
        this.$router.push('/inicio')
      }
    }
  },
  computed: {
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    }
  },

  watch: {
    verificandoDadosAlteradosRoot (val) {
      if (val) {
        this.verificarModificaoNoItem()
      }
    }
  },
  async beforeMount () {
    this.LimpaErros()
    await this.getConfig()
    this.key++
  }

}
</script>

<style scoped>
</style>
