import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIETO extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 9 && this.inscricao.length !== 11) {
      return false
    }

    const peso = [9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this._retornarInscricaoSemDV()
    const digito = this.inscricao.substring(this.inscricao.length - 1)
    let soma = 0

    for (let i = 0; i < ie.length; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    const resultado = (soma % 11) <= 1 ? 0 : 11 - (soma % 11)

    return resultado === Number.parseInt(digito)
  }

  _retornarInscricaoSemDV () {
    return this.inscricao.length === 9
      ? this.inscricao.substring(0, this.inscricao.length - 1)
      : this.inscricao.substring(0, 2) + this.inscricao.substring(4, this.inscricao.length - 1)
  }
}
