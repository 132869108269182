import ValidacaoIERJ from './validacao/ValidacaoIERJ'
import ValidacaoIESP from './validacao/ValidacaoIESP'
import ValidacaoIEAC from './validacao/ValidacaoIEAC'
import ValidacaoIEAL from './validacao/ValidacaoIEAL'
import ValidacaoIEAM from './validacao/ValidacaoIEAM'
import ValidacaoIEAP from './validacao/ValidacaoIEAP'
import ValidacaoIEBA from './validacao/ValidacaoIEBA'
import ValidacaoIECE from './validacao/ValidacaoIECE'
import ValidacaoIEDF from './validacao/ValidacaoIEDF'
import ValidacaoIEES from './validacao/ValidacaoIEES'
import ValidacaoIEGO from './validacao/ValidacaoIEGO'
import ValidacaoIEMA from './validacao/ValidacaoIEMA'
import ValidacaoIEMG from './validacao/ValidacaoIEMG'
import ValidacaoIEMS from './validacao/ValidacaoIEMS'
import ValidacaoIEMT from './validacao/ValidacaoIEMT'
import ValidacaoIEPA from './validacao/ValidacaoIEPA'
import ValidacaoIEPB from './validacao/ValidacaoIEPB'
import ValidacaoIEPE from './validacao/ValidacaoIEPE'
import ValidacaoIEPI from './validacao/ValidacaoIEPI'
import ValidacaoIEPR from './validacao/ValidacaoIEPR'
import ValidacaoIERN from './validacao/ValidacaoIERN'
import ValidacaoIERO from './validacao/ValidacaoIERO'
import ValidacaoIERR from './validacao/ValidacaoIERR'
import ValidacaoIERS from './validacao/ValidacaoIERS'
import ValidacaoIESC from './validacao/ValidacaoIESC'
import ValidacaoIESE from './validacao/ValidacaoIESE'
import ValidacaoIETO from './validacao/ValidacaoIETO'

function init () {
  const verificacoesEstaduaisLocal = new Map()
  verificacoesEstaduaisLocal.set('RJ', ValidacaoIERJ)
  verificacoesEstaduaisLocal.set('SP', ValidacaoIESP)
  verificacoesEstaduaisLocal.set('AC', ValidacaoIEAC)
  verificacoesEstaduaisLocal.set('AL', ValidacaoIEAL)
  verificacoesEstaduaisLocal.set('AM', ValidacaoIEAM)
  verificacoesEstaduaisLocal.set('AP', ValidacaoIEAP)
  verificacoesEstaduaisLocal.set('BA', ValidacaoIEBA)
  verificacoesEstaduaisLocal.set('CE', ValidacaoIECE)
  verificacoesEstaduaisLocal.set('DF', ValidacaoIEDF)
  verificacoesEstaduaisLocal.set('ES', ValidacaoIEES)
  verificacoesEstaduaisLocal.set('GO', ValidacaoIEGO)
  verificacoesEstaduaisLocal.set('MA', ValidacaoIEMA)
  verificacoesEstaduaisLocal.set('MG', ValidacaoIEMG)
  verificacoesEstaduaisLocal.set('MS', ValidacaoIEMS)
  verificacoesEstaduaisLocal.set('MT', ValidacaoIEMT)
  verificacoesEstaduaisLocal.set('PA', ValidacaoIEPA)
  verificacoesEstaduaisLocal.set('PB', ValidacaoIEPB)
  verificacoesEstaduaisLocal.set('PE', ValidacaoIEPE)
  verificacoesEstaduaisLocal.set('PI', ValidacaoIEPI)
  verificacoesEstaduaisLocal.set('PR', ValidacaoIEPR)
  verificacoesEstaduaisLocal.set('RN', ValidacaoIERN)
  verificacoesEstaduaisLocal.set('RO', ValidacaoIERO)
  verificacoesEstaduaisLocal.set('RR', ValidacaoIERR)
  verificacoesEstaduaisLocal.set('RS', ValidacaoIERS)
  verificacoesEstaduaisLocal.set('SC', ValidacaoIESC)
  verificacoesEstaduaisLocal.set('SE', ValidacaoIESE)
  verificacoesEstaduaisLocal.set('TO', ValidacaoIETO)

  return verificacoesEstaduaisLocal
}

const verificacoesEstaduais = init()

export default class InscricaoEstadual {
  validarIE (estado = '', inscricao = '') {
    if (estado.toUpperCase() === 'ex'.toUpperCase()) {
      return true
    }
    if (verificacoesEstaduais.has(estado.toUpperCase()) && inscricao.toUpperCase() !== 'ISENTO') {
      const S = verificacoesEstaduais.get(estado.toUpperCase())
      const inscricaoLimpa = inscricao.replace(/[^\w\s]/gi, '')
      return new S(inscricaoLimpa).validar()
    }
    return inscricao.toUpperCase() === 'ISENTO'
  }
}
