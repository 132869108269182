const decimalFormat = new Intl.NumberFormat()

const moneyFormat = new Intl.NumberFormat(getLocale(), {
  style: 'currency', currency: 'BRL'
})

const dateTimeFormat = new Intl.DateTimeFormat(getLocale(), {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
})

const dateFormat = new Intl.DateTimeFormat(getLocale(), {
  timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric'
})

function getLocale () {
  if (navigator.languages !== undefined) {
    return navigator.languages[0]
  } else {
    return navigator.language
  }
}

export function formatMoney (value) {
  if (!value === 0 && !value) {
    return null
  }
  return moneyFormat.format(value)
}

export function formatNumber (value) {
  if (!value) {
    return null
  }
  return this.numberFormat.format(value)
}

export function formatDateTime (value) {
  if (!value) {
    return null
  }
  if (value instanceof Date) {
    return dateTimeFormat.format(value)
  } else {
    return dateTimeFormat.format(new Date(value))
  }
}

export function formatDate (value) {
  if (!value) {
    return null
  }
  if (value instanceof Date) {
    return dateFormat.format(value)
  } else {
    return dateFormat.format(new Date(value))
  }
}

export function format (value) {
  if (value instanceof Date) {
    return dateTimeFormat.format(value)
  } else if (typeof (value) === 'number') {
    return decimalFormat.format(value)
  } else if (typeof (value) === 'string') {
    return formatText(value)
  } else {
    return value
  }
}

export function formatText (value) {
  if (!value) {
    return null
  }

  if (typeof String && value.length > 40) {
    return value.substring(0, 39) + '...'
  }

  return value
}

export function formatTextSelect (value, tamanho = 80) {
  return value.length > tamanho ? value.substring(0, tamanho - 1) + '...' : value
}
