import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIEPR extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 10) {
      return false
    }

    const digito1 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 2, this.inscricao.length - 1))
    const digito2 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))

    const digitoCalculado1 = this._calcularDigito1()
    const digitoCalculado2 = this._calcularDigito2()

    return digito1 === digitoCalculado1 && digito2 === digitoCalculado2
  }

  _calcularDigitoVerificador (peso = [0], ie = '') {
    let soma = 0
    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }
    return (soma % 11) <= 1 ? 0 : 11 - (soma % 11)
  }

  _calcularDigito1 () {
    const peso = [3, 2, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 2)

    return this._calcularDigitoVerificador(peso, ie)
  }

  _calcularDigito2 () {
    const peso = [4, 3, 2, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)

    return this._calcularDigitoVerificador(peso, ie)
  }
}
