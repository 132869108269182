class Toast {
  constructor (toast) {
    this.tipo = ''
    this.titulo = ''
    this.subtitulo = ''
    this.identificador = ''
    this.tamanho = ''
    if (toast) {
      Object.assign(this, toast)
    }
  }
}

export default Toast
