<template>
  <div v-if="status === 'carregando'" class="lds-dual-ring"></div>
  <div v-else>
    <div v-if="pValue" style="height: 25px">
      <input
        :id="pId"
        :name="pName"
        style="position: static"
        checked="checked"
        type="checkbox"
        class="switch is-rounded is-success is-small"
        @click="_clique"
      />
      <label htmlfor="" v-bind="{ for: pName }">{{ pLabel }}</label>
    </div>
    <div v-else style="height: 25px">
      <input
        :id="pId"
        :name="pName"
        style="position: static"
        type="checkbox"
        class="switch is-rounded is-success is-small"
        @click="_clique"
      />
      <label htmlfor="" v-bind="{ for: pName }">{{ pLabel }}</label>
    </div>
  </div>
  <!-- v-bind="{ for: item.cdempresa }" -->
</template>
<script>
export default {
  props: {
    pId: {
      type: String,
      default: ''
    },
    pName: {
      type: String,
      default: ''
    },
    pValue: {
      type: String,
      default: ''
    },
    pLabel: {
      type: String,
      default: ''
    },
    item: Object,
    integracao: Function
  },
  data () {
    return {
      status: ''
    }
  },
  methods: {
    async _clique () {
      this.status = 'carregando'
      this.integracao(this.item)
        .then((_res) => {
          this.status = ''
        })
        .catch((_err) => {
          this.status = ''
        })
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../../node_modules/bulma/bulma.sass';
@import '../../../../node_modules/bulma-switch';

.lds-dual-ring {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding-left: 15px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 25px;
  height: 25px;
  margin: -1px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: rgb(165, 3, 3) transparent rgb(165, 3, 3) transparent;
  animation: lds-dual-ring 1s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
