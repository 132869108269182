import { microServiceRequest } from '@packonline/packonline-reutilizaveis-frontend/core/services/micro-service-request'
import { env } from '@/env'

const API = '/api/v1/livros/diario'
export default class ConfiguracoesAdicionaisDiario {
  constructor (obj) {
    this.ImprimeValoresTranportar = null
    this.stTotalizaPorDia = null
    this.DesconsiderarLancAposEnc = null
    this.stImpContraPartida = null
    this.stDocumento = null
    this.stImprPlanoDeContas = null
    this.ImprimeAsteriscosFimRelatorioDB = null

    if (obj) {
      Object.assign(this, obj)
    }
  }

  static async salvaOpcoes (objDiario) {
    await microServiceRequest.post(env.msContabil, `${API}/atualizar-diario-configuracoes-principais`, objDiario)
  }
}
