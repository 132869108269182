<template>
  <div>
    <div
      v-if="exibirAviso"
      :style="`
        background-color: ${backgroundColor};
        padding: 15px;
        width: ${tamanhoAviso};
        padding-left: 20px;
        padding-bottom: 0;
        position: fixed;
        right: 1.5rem;
        top: 1rem; z-index: 30;
        border-radius: 5px;
      `"
    >
      <p
        :style="
          type === 'info' ||
          type === 'success' ||
          type === 'error'
            ? 'font-size: 18px; margin-bottom: 1.5rem; color: white; margin-right: 10px'
            : 'font-size: 18px; margin-bottom: 1.5rem; margin-right: 10px'
        "
      >
        {{ title }}
      </p>
      <div class="columns is-multiline">
        <div class="column is-1 p-0 pl-3">
          <span class="icon is-medium"
            ><span
              :style="`opacity: 0.5; color: ${colorIcon}`"
              :class="`fas fa-2x fa-${icon}`"
            ></span
          ></span>
        </div>
        <div :class="`column is-10 ${margem}`" style="overflow-y: auto; max-height: 300px; padding: 0">
          <div
            :style="
              type === 'info' ||
              type === 'success' ||
              type === 'error'
                ? 'color: white'
                : ''
            "
          >
            <slot></slot>
          </div>
        </div>
        <div class="column is-12">
          <div class="is-flex is-justify-content-flex-end">
            <button
              :style="`padding-inline: 2rem; ${posicionamentoBotao}`"
              class="button"
              @click="esconderAviso()"
            >
              Entendi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, watch, useSlots } from 'vue'

const emits = defineEmits(['avisoVisivel'])

const slots = useSlots()

const timer = ref('')

const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  exibirAviso: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  }
})

const tipo = ref(props.type)

const icon = computed(() => {
  let icone = ''
  switch (tipo.value) {
    case 'warning':
      icone = 'exclamation-triangle'
      break
    case 'info':
      icone = 'info-circle'
      break
    case 'success':
      icone = 'check-circle'
      break
  }
  return icone
})

const colorIcon = computed(() => {
  let iconeCor = ''
  switch (tipo.value) {
    case 'warning':
      iconeCor = 'darkgoldenrod'
      break
    case 'info':
      iconeCor = 'lightblue'
      break
    case 'success':
      iconeCor = 'darkgreen'
      break
  }
  return iconeCor
})

const backgroundColor = computed(() => {
  let corDeFundo = ''
  switch (tipo.value) {
    case 'warning':
      corDeFundo = 'rgb(255,245,110,255)'
      break
    case 'info':
      corDeFundo = 'rgba(63,72,204,255)'
      break
    case 'success':
      corDeFundo = 'rgba(20,163,1,255)'
      break
  }
  return corDeFundo
})

const margem = computed(() => {
  let margin = ''
  if (props.title.length < 70) {
    margin = 'ml-3'
  }
  return margin
})

const posicionamentoBotao = computed(() => {
  let pos = ''
  if (slots.default()[0]) {
    if (slots.default()[0].children.length < 80) {
      pos = 'position: relative; bottom: 2rem'
    }
  } else {
    pos = 'margin-bottom: 1rem'
  }
  return pos
})

const tamanhoAviso = computed(() => {
  let aviso = ''
  if (props.title.length < 70) {
    aviso = '40vw'
  } else {
    aviso = '50vw'
  }
  return aviso
})

watch(() => props.exibirAviso, (val) => {
  if (val === true) {
    timer.value = setTimeout(() => {
      emits('avisoVisivel', false)
    }, 10000)
  }
})

const esconderAviso = () => {
  clearTimeout(timer.value)
  emits('avisoVisivel', false)
}

</script>

<style lang="scss" scoped>

</style>
