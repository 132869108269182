import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIERN extends ValidacaoIE {
  validar () {
    if (this.inscricao.length < 9 || this.inscricao.length > 10) {
      return false
    }

    const digito = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))
    const prefixo = Number.parseInt(this.inscricao.substring(0, 2))

    let isValido

    if (this.inscricao.length === 9) {
      isValido = digito === this._calcularResto9Digitos() && prefixo === 20
    } else {
      isValido = digito === this._calcularResto10Digitos() && prefixo === 20
    }

    return isValido
  }

  _calcularSoma (peso = [0], ie = '') {
    let soma = 0
    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }
    return soma
  }

  _calcularResto9Digitos () {
    const peso = [9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)

    const soma = this._calcularSoma(peso, ie) * 10

    return soma % 11 >= 10 ? 0 : soma % 11
  }

  _calcularResto10Digitos () {
    const peso = [10, 9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)

    const soma = this._calcularSoma(peso, ie) * 10

    return soma % 11 >= 10 ? 0 : soma % 11
  }
}
