import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIEGO extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 9 || !this._verificaPrefixoIe()) {
      return false
    }

    const peso = [9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)
    const digito = this.inscricao.substring(this.inscricao.length - 1)
    let soma = 0

    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    let resultado
    const resto = soma % 11

    if (resto === 0) {
      resultado = resto
    } else if (resto === 1) {
      const maximo = 10119997
      const minimo = 10103105
      const codigo = Number.parseInt(ie)

      if (codigo <= maximo && codigo >= minimo) {
        resultado = resto
      } else {
        resultado = 0
      }
    } else {
      resultado = 11 - resto
    }

    return resultado === Number.parseInt(digito)
  }

  _verificaPrefixoIe () {
    const codUf = Number.parseInt(this.inscricao.substring(0, 2))
    const codigoUf = [10, 11, 15]
    return codigoUf.find(e => e === codUf)
  }
}
