import ValidacaoIE from './ValidacaoIE'

const LENGTH_IE = 13

export default class ValidacaoIEMG extends ValidacaoIE {
  constructor (inscricao = '') {
    super(ValidacaoIE.zeroFill(inscricao, LENGTH_IE))
  }

  validar () {
    if (this.inscricao.length !== LENGTH_IE) {
      return false
    }
    const digito1 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 2, this.inscricao.length - 1))
    const digito2 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))

    const digitoCalculado1 = this._calcularDigito1()
    const digitoCalculado2 = this._calcularDigito2(digitoCalculado1)

    return digito1 === digitoCalculado1 && digito2 === digitoCalculado2
  }

  _calcularDigitoVerificador (peso = [0], ie = '', segundoDigito = false) {
    let soma = Number.parseFloat(0.0)

    for (let i = 0; i <= ie.length - 1; i++) {
      const valor = super.getSoma(ie, i, peso)

      if (String(valor).length > 1 && !segundoDigito) {
        const charArray = [...String(valor)]

        for (const c of charArray) {
          soma += Number.parseFloat(c)
        }
      } else {
        soma += valor
      }
    }

    let resultado

    if (!segundoDigito) {
      const div = (soma / 10.0)
      resultado = (Math.ceil(div) * 10.0) - soma
    } else {
      const mod = soma % 11
      resultado = mod <= 1 ? 0 : 11 - mod
      resultado = Math.abs(resultado)
    }

    return Number.parseInt(resultado)
  }

  _calcularDigito1 () {
    const peso = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]
    const ie = this.inscricao.substring(0, 3) + 0 + this.inscricao.substring(3, this.inscricao.length - 2)

    return this._calcularDigitoVerificador(peso, ie, false)
  }

  _calcularDigito2 (digitoCalculado1 = 0) {
    const peso = [3, 2, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 2) + digitoCalculado1

    return this._calcularDigitoVerificador(peso, ie, true)
  }
}
