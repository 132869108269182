import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIERR extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 9) {
      return false
    }

    const peso = [1, 2, 3, 4, 5, 6, 7, 8]

    const ie = this.inscricao.substring(0, this.inscricao.length - 1)
    const digito = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))
    const prefixo = Number.parseInt(this.inscricao.substring(0, 2))
    let soma = 0

    for (let i = 0; i < ie.length; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    return soma % 9 === digito && prefixo === 24
  }
}
