import { Service } from '../../../../../service'

class IntegracaoEmLoteService extends Service {
  async post (url, dto) {
    const init = {
      headers: this.headers,
      method: 'POST',
      body: JSON.stringify(dto)
    }

    const response = await fetch(this.api + url, init)
    if (response.ok) {
      return response
    }

    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }
}

export const integracaoEmLoteService = new IntegracaoEmLoteService()
