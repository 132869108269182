import ValidacaoIE from './ValidacaoIE'

const LENGTH_IE = 11

export default class ValidacaoIEMT extends ValidacaoIE {
  constructor (inscricao) {
    super(ValidacaoIE.zeroFill(inscricao, LENGTH_IE))
  }

  validar () {
    if (this.inscricao.length !== LENGTH_IE) {
      return false
    }

    const peso = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)
    const digito = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))
    let soma = 0

    for (let i = 0; i < ie.length; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    const digitoCalculado = soma % 11 <= 1 ? 0 : 11 - (soma % 11)

    return digitoCalculado === digito
  }
}
