<template>
  <div v-if="this.status === 'is-loading'">
      <span class="button is-large is-fullwidth is-loading"></span>
  </div>
  <div v-if="this.status === 'is-empty'">
      <div :class="!this.exibeCabecalho ? 'level is-hidden' : 'level '">
          <div class="level-left">
              <slot name="new-item"></slot>
          </div>
      </div>
      <div class="notification is-info animated fadeIn">
          Nada aqui. Faça uma nova pesquisa ou mude os critérios.
      </div>
  </div>
  <div v-if="this.status === 'is-danger'">
      <div :class="!this.exibeCabecalho ? 'level is-hidden' : 'level'">
          <div class="level-left">
              <slot name="new-item"></slot>
          </div>
      </div>
      <div class="notification is-danger animated fadeIn">
          Ops... Estamos com problemas... Tente mais uma vez! :(
          <button class="button is-small is-pulled-right" @click="() => this.retry()" >
              Tentar!
          </button>
      </div>
  </div>

  <div v-if="this.status === ''" :class="`level  ${this.tamanhoMargemInferiorContainer ? 'mb-'+this.tamanhoMargemInferiorContainer:''}`">
      <div class="level-left">
          <slot name="new-item"></slot>
      </div>
      <div class="level-right">
          <p class="level-item control has-icons-left">
              <input v-if="this.showSearch" ref="search" style="background-color: inherit" class="input is-small" type="text" :placeholder="this.searchPlaceHolder ?
                              this.searchPlaceHolder : 'Digite aqui sua busca'" :value="this.busca" @input="(event) =>
                                  this.search(event)"/>
              <span v-if="this.showSearch" class="icon is-left"><span class="fas fa-search"></span></span>
          </p>
      </div>
  </div>
  <slot v-if="this.status === ''" name="new-label"></slot>
  <div :id="this.idDatatableContainer"
  :class="`panel table-container ${this.isShadowless ? 'is-shadowless ' : ''} ${this.isRadiusless ? 'is-radiusless ' : ''} ${this.isScrollable && 'is-tableScrollable '} ${this.isScrollableHorizontal ? 'is-tableScrollHorizontal ' : ''} ${this.isVisibleOverflow ? 'is-visibleOverflow ' : ''} ${this.tamanhoMargemInferiorContainer ? 'mb-'+this.tamanhoMargemInferiorContainer : ''}`" :style="this.customStyle">

  <table :id="this.idDataTable"
      :class="`table ${this.tableClass !== '' ? this.tableClass : 'is-striped is-hoverable is-fullwidth '} ${this.isRadiusless ? 'is-radiusless' : ''}`">
      <thead v-if="this.pData.length > 0">
          <tr class="is-size-7" >
              <th v-if="this.isSelectable && this.isMultipleSelection" style="width: 1.4%">
                  <label class="checkbox" style="padding-left: 0.9px;">
                      <input type="checkbox" :checked="this.checarSeTodosOsItensValidosEstaoSelecionados" @click="this.selectAllItens">
                  </label>
              </th>
              <th v-for="(column, index) in this.dataSource.columns" :key="index"
                  :class="column.classes ? column.classes : ''" :style="column.style ? column.style : ''"
              >
                  <a @click="(event) => this.sort(event, column.name)">
                      {{column.label}}
                  </a>
              </th>
              <th v-if="this.showButtons">
                  <a v-if="this.isScrollableHorizontal" class="is-invisible">table-head-action-buttons-invisible</a>
              </th>
          </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in this.dataSource.itens" :key="index">
        <template v-if="!this.exibicaoEmCard">

          <tr v-if="this.isSelectable && this._itemVisivel(item) && !this.noIcon" @click="(event) => this.selectItem(event, item)" :class="this.isSelectedItem(item) && isShowMultipleSelection ? 'is-selected' : ''">
            <td v-if="this.isSelectable && this.isMultipleSelection">
              <label class="checkbox">
                <input type="checkbox" id="checkbox" :disabled="this.isDisabledItem(item)" :checked="this.isSelectedItem(item)">
              </label>
            </td>
            <td v-for="(column, indexColumn) in this.dataSource.columns" :key="indexColumn" :class="column.classes ? column.classes : ''" :data-label="column.label+'&nbsp;'" style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :style="column.styleItems">
              <slot v-if="column.slot" :name="column.name" :item="item" />
              <span v-else v-html="this.highlight(this._renderValue(item, column), this.busca, item)"></span>
            </td>

            <td v-if="this.showButtons" style="padding-left: 0rem; padding-right: 0.5rem">
              <button v-if="this.showButtonsDefault && !noDelete" :disabled="this.disabled ? this.disabled : dataSource.disableDeleteButtonIf(item)" type="button" :class="this.showDelete ? 'button is-small is-pulled-right' : 'is-hidden'" @click="() => this.preDelete(item)">
                <span class="icon"><span class="fas fa-trash-alt"></span></span>&nbsp;&nbsp;&nbsp;&nbsp;Excluir
              </button>
              <button v-if="this.showButtonsDefault && !noEdit" :disabled="this.disabled ? this.disabled : dataSource.disableEditButtonIf(item)" class="button is-small is-pulled-right" type="button" @click="this.select(item)">
                <span class="icon"><span class="fas fa-edit"></span></span>&nbsp;&nbsp;&nbsp;&nbsp;Editar
              </button>
              <template v-for="(botao, indexBotao) in this.botoesExternos.botoes" :key="indexBotao">
                <button type="button" :class="'button is-small is-pulled-right'" @click="() => this.cliqueBotaoExterno(item, botao.id)">
                  <span class="icon" v-html="botao.icone"></span>&nbsp;&nbsp;&nbsp;&nbsp;{{botao.nome}}
                </button>
              </template>
            </td>

          </tr>
          <tr v-else-if="this._itemVisivel(item) && this.noIcon">
            <td v-if="this.isSelectable && this.isMultipleSelection">
                <label class="checkbox">
                  <input type="checkbox" :disabled="this.isDisabledItem(item)" :checked="this.isSelectedItem(item)" @click="(event) => this.selectItem(event, item)">
                </label>
              </td>
              <td v-for="(column, indexColumn) in this.dataSource.columns" :key="indexColumn" :class="column.classes ? column.classes : ''" :data-label="column.label+'&nbsp;'">
              <slot v-if="column.slot" :name="column.name" :item="item" />
              <span v-else v-html="this.highlight(this._renderValue(item, column), this.busca, item)"></span>
              </td>
              <td v-if="this.showButtons">
                <button v-if="this.showButtonsDefault" :disabled="this.disabled ? this.disabled : dataSource.disableDeleteButtonIf(item)" type="button" :class="this.showDelete ? 'button is-small is-pulled-right' : 'is-hidden'" @click="() => this.preDelete(item)">
                  Excluir
                </button>
                <button v-if="this.showButtonsDefault" :disabled="this.disabled ? this.disabled : dataSource.disableEditButtonIf(item)" class="button is-small is-pulled-right" type="button" @click="this.select(item)">
                  Editar
                </button>
                <template v-for="(botao, indexBotao) in this.botoesExternos.botoes" :key="indexBotao">
                  <button type="button" :class="'button is-small is-pulled-right'" @click="() => this.cliqueBotaoExterno(item, botao.id)">
                    <span class="icon" v-html="botao.icone"></span>
                  </button>
                </template>
              </td>
          </tr>
        </template>

          <template v-if="this.exibicaoEmCard">
            <tr v-if="this.isSelectable && !this.isMultipleSelection && this._itemVisivel(item)" :class="this.isSelectedItem(item) ? 'is-selected' : ''" @click="(event) => this.selectItem(event, item)">
              <td>
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <div class="columns is-multiline">
                      <div class="column is-1" v-if="this.isSelectable && this.isMultipleSelection">
                        <label class="checkbox">
                          <input type="checkbox" :disabled="this.isDisabledItem(item)" :checked="this.isSelectedItem(item)" @click="(event) => this.selectItem(event, item)">
                        </label>
                      </div>
                      <div id="conteudo-card" class="column is-8" v-for="(column, indexColumn) in this.dataSource.columns" :key="indexColumn" :class="column.classes ? column.classes : ''">
                        {{column.label}}:
                        <span v-html="this.highlight(this._renderValue(item, column), this.busca, item)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </td>
              <td v-if="this.showButtons">
                <div class="card-content" style="padding-top: 0.2rem;">
                  <div class="content">
                    <template v-for="(botao, indexBotao) in this.botoesExternos.botoes" :key="indexBotao">
                      <button type="button" :class="'button is-small is-pulled-right'" @click="() => this.cliqueBotaoExterno(item, botao.id)">
                        <span class="icon" v-html="botao.icone"></span>
                      </button>
                    </template>
                    <button v-if="this.showButtonsDefault" :disabled="this.disabled" class="button is-small is-pulled-right" type="button" @click="this.select(item)">
                      <span class="icon"><span class="fas fa-edit"></span></span>
                    </button>
                    <button v-if="this.showButtonsDefault" :disabled="this.disabled" type="button" :class="this.showDelete ? 'button is-small is-pulled-right' : 'is-hidden'" @click="() => this.preDelete(item)">
                      <span class="icon"><span class="fas fa-trash-alt"></span></span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
    </table>

    <button v-if="this.isScrollable && !this.paginacao && this.dataSource.loadMore"
      :class="(this.dataSourceItens.count < 20) || (this.hideButtonMore) ? ' button is-link is-outlined is-pulled-right mb-2 mr-2 is-hidden' : 'button is-link is-outlined is-pulled-right mb-2 mr-2'"
      @click="(event) => this.loadMore(event)">
      Mais...
    </button>
    <button v-if="!this.isScrollable && !this.paginacao  && this.dataSource.loadMore"
      :class="(this.dataSourceItens.count < 20) || (this.hideButtonMore) ? 'button is-link is-outlined is-pulled-right is-hidden' : 'button is-link is-outlined is-pulled-right'"
      @click="(event) => this.loadMore(event)">
      Mais...
    </button>
  </div>
  <pagination
    v-if="paginacao && pQuantidadeDeItems > 0"
    :total="pQuantidadeDeItems"
    :pQuantidadeDeItemsExibindo="this.dataSource.itens.length"
    :idDivUtilizada="this.idDivUtilizadaPaginacao"
    :pPages="pPages"
    :lastSearch="lastSearch"
    @change-page="this.nextPage"
  />

  <div v-if="this.modalConfirmacaoVisivel" class="modal animated fadeIn is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Certo da exclusão?</p>
        </header>
        <footer class="modal-card-foot">
            <button :class="`${this.isAwaitDeleteClass ? 'button is-loading' : 'button'}`" @click="() => this.delete()">Sim</button>
            <button class="button is-primary" @click="() => this.cancelPreDelete()">Não</button>
        </footer>
    </div>
  </div>
</template>

<script>
import { format } from '../../../util/intl.js'
import { utils } from '../../../util/utils.js'
import Pagination from '../../Paginacao/index.vue'

export default {
  components: {
    Pagination
  },
  emits: ['search'],
  props: {
    pStatus: String,
    pDataSource: [],
    pData: [],
    pHideButtonMore: Boolean,
    pBotoesExternos: [],
    cliqueBotaoExterno: Function,
    pShowButtons: {
      type: Boolean,
      default: true
    },
    pExibicaoEmCard: Boolean,
    pQuantidadeDeItems: Number,
    nextPage: Function,
    paginacao: {
      type: Boolean,
      default: true
    },
    pSearch: {
      type: Boolean,
      default: true
    },
    idDivUtilizadaPaginacao: {
      type: [String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modalDelecaoVisivel: {
      type: Function,
      default: () => { return true }
    },
    customStyle: {
      type: String,
      default: ''
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    noDelete: {
      type: Boolean,
      default: false
    },
    noEdit: {
      type: Boolean,
      default: false
    },
    customSearch: {
      type: Boolean,
      default: false
    },
    customHighlighters: {
      type: Function
    },
    pPages: {
      type: [String, Number]
    },
    isMultipleSelection: {
      type: Boolean,
      default: false
    },
    isShowMultipleSelection: {
      type: Boolean,
      default: true
    }

  },
  data () {
    return {
      dataSource: {
        columns: [],
        itens: [],
        itensSelected: [],
        itensDisabled: [],
        loadMore: async (event) => console.log('[load more] ' + event),
        select: (item) => console.log('[select] ' + item),
        delete: async (item) => console.log('[delete] ' + item),
        disableDeleteButtonIf: (item) => false,
        disableEditButtonIf: (item) => false
      },
      status: '',
      data: [],
      tableClass: '',
      showButtons: true,
      showButtonsDefault: true,
      showSearch: this.pSearch,
      showDelete: true,
      isSelectable: true,
      hideButtonMore: false,
      isShadowless: false,
      isRadiusless: false,
      isScrollable: true,
      isVisibleOverflow: false,
      busca: '',
      ascending: true,
      preDeleted: null,
      searchPlaceHolder: '',
      maxHeight: 50,
      isShowingFullText: true,
      isAwaitDelete: false,
      isAwaitDeleteClass: false,
      isScrollableHorizontal: false,
      exibeCabecalho: true,
      tamanhoMargemInferiorContainer: null,
      texto: '<span class="fa-dollar-sign"></span>',
      dataSourceItens: { count: 0, firstItem: '' },
      botoesExternos: {
        botoes: []
      },
      exibicaoEmCard: false,
      idDataTable: 'datatableFixed',
      idDatatableContainer: 'datatable-container-fixed',
      modalConfirmacaoVisivel: false,
      lastSearch: Date.now()

    }
  },

  watch: {
    pDataSource: {
      immediate: true,
      handler () {
        this.dataSource = {}
        this.dataSource = this.pDataSource
        if (!this.dataSource.disableDeleteButtonIf) {
          this.dataSource.disableDeleteButtonIf = () => false
        }
        if (!this.dataSource.disableEditButtonIf) {
          this.dataSource.disableEditButtonIf = () => false
        }
      }
    },
    pData: {
      immediate: true,
      handler () {
        this.dataSource.itens = this.pData
        this._verificarDataSourceItens()
        if (this.pSearch) {
          this.$nextTick().then(res => {
            if (this.$refs.search) {
              this.$refs.search.focus()
            }
          })
        }
      }
    },
    pStatus: {
      immediate: true,
      handler () {
        this.status = this.pStatus
      }
    },
    pHideButtonMore: {
      immediate: true,
      handler () {
        this.hideButtonMore = this.pHideButtonMore
      }
    },
    pBotoesExternos: {
      immediate: true,
      handler () {
        if (this.pBotoesExternos) {
          this.botoesExternos = this.pBotoesExternos
        }
      }
    },
    pShowButtons: {
      immediate: true,
      handler () {
        this.showButtons = this.pShowButtons
      }
    },
    pExibicaoEmCard: {
      immediate: true,
      handler () {
        this.exibicaoEmCard = this.pExibicaoEmCard
        if (this.pExibicaoEmCard) {
          this.idDataTable = 'datatableCard'
          this.idDatatableContainer = 'datatable-container-card'
        }
      }
    }
  },

  methods: {
    search (event) {
      // const busca = event.composedPath()[0].value
      const busca = event.target.value

      if (this.keyPressTimedOut) {
        clearTimeout(this.keyPressTimedOut)
      }

      this.keyPressTimedOut = setTimeout(() => {
        this.busca = busca
        this.keyPressTimedOut = null
        if (this.customSearch) {
          this.$emit('search', busca)
          this.lastSearch = Date.now()
        }
      }, 1000)
    },

    _getValueOf (values, field) {
      const props = field.split('.')
      let i = 0
      let el = values

      try {
        do {
          el = el[props[i]]

          if (i === props.length - 1) {
            return el
          }

          i++
        } while (i < props.length)
      } catch (e) {
        return null
      }
    },

    sort (event, field) {
      this._removeSortArrows()

      const values = [...this.dataSource.itens]

      if (this.ascending) {
        this.dataSource.itens = values.sort((a, b) => {
          const valorDeA = this._getValueOf(a, field)
          const valorDeB = this._getValueOf(b, field)

          if (valorDeA === valorDeB) {
            return 0
          }

          if (valorDeA > valorDeB) {
            return -1
          }

          if (valorDeA < valorDeB) {
            return +1
          }
        })

        event.composedPath()[0].innerHTML = event.composedPath()[0].innerHTML + ' \u2191'
      } else {
        this.dataSource.itens = values.sort((a, b) => {
          const valorDeA = this._getValueOf(a, field)
          const valorDeB = this._getValueOf(b, field)

          if (valorDeA === valorDeB) {
            return 0
          }

          if (valorDeA < valorDeB) {
            return -1
          }

          if (valorDeA > valorDeB) {
            return +1
          }
        })

        event.composedPath()[0].innerHTML = event.composedPath()[0].innerHTML + ' \u2193'
      }

      this.ascending = !this.ascending
    },

    _verificarDataSourceItens () {
      if (this.dataSource.itens.length > 0) {
        const currentFirstItem = JSON.stringify(this.dataSource.itens[0])

        if (this.dataSourceItens.firstItem !== currentFirstItem) {
          this.dataSourceItens.count = 0
          this.dataSourceItens.firstItem = currentFirstItem
        }

        if (this.dataSourceItens.count < this.dataSource.itens.length) {
          this.dataSourceItens.count = this.dataSource.itens.length
        }
      }
    },

    _removeSortArrows () {
      const headersLink = Array.from(document.querySelectorAll('th > a'))

      headersLink.forEach(a => {
        a.innerHTML = a.innerHTML.replace(' \u2191', '').replace(' \u2193', '')
      })
    },

    _renderValue (row, column) {
      const properties = column.name.split('.')
      let value = row

      for (let i = 0; i < properties.length; i++) {
        value = value[properties[i]]
      }

      return column.format ? column.format(value) : this.isShowingFullText ? value : format(value)
    },

    _itemVisivel (item) {
      if (!this.busca) {
        return true
      }

      if (this.customSearch) {
        return true
      } else {
        let achouItemNaBusca = false

        for (var i = 0; i < this.dataSource.columns.length; i++) {
          const stringfied = '' + this._renderValue(item, this.dataSource.columns[i])
          if (stringfied.toLowerCase().includes(this.busca.toLowerCase())) {
            achouItemNaBusca = true
          }
        }

        return achouItemNaBusca
      }
    },

    highlight (value, busca, item) {
      if (this.customHighlighters) {
        return this.customHighlighters(value, busca, item)
      } else {
        const highlighters = []
        if (!busca) {
          highlighters.unshift('')
          return value
        }

        busca = busca.trim()

        if (this.customSearch) {
          return this.highlightersCustomSearchDefault(value, busca, item)
        } else {
          const stringfied = '' + value

          if (!stringfied.toLowerCase().includes(busca.toLowerCase())) {
            return value
          }

          highlighters.unshift(stringfied)

          return `<span class="has-background-warning">${value}</span>`
        }
      }
    },

    highlightersCustomSearchDefault (value, busca, item) {
      const stringfied = '' + value

      const stringfiedClear = parseFloat(busca) ? utils.removeAcentos(utils.clearMaskNumber(busca.toLowerCase())) : utils.removeAcentos(busca.toLowerCase())
      if (!utils.removeAcentos(stringfied).toLowerCase().includes(stringfiedClear)) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLocaleLowerCase().indexOf(stringfiedClear)
      const end = init + stringfiedClear.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(extractedValue, `<span class="has-background-warning">${extractedValue}</span>`)
    },

    async loadMore (event) {
      await this.dataSource.loadMore(event)
    },

    select (item) {
      this.dataSource.select(item)
    },

    async preDelete (item) {
      this.preDeleted = item
      if (await this.modalDelecaoVisivel(item)) {
        this.modalConfirmacaoVisivel = true
      } else {
        this.delete()
      }
    },

    cancelPreDelete () {
      this.preDeleted = null
      this.modalConfirmacaoVisivel = false
    },

    async delete () {
      if (this.isAwaitDelete) {
        this.isAwaitDeleteClass = true

        try {
          await this.dataSource.delete(this.preDeleted)
        } finally {
          this.isAwaitDeleteClass = false
          this.modalConfirmacaoVisivel = false
        }
      } else {
        this.modalConfirmacaoVisivel = false
        await this.dataSource.delete(this.preDeleted)
      }
    },

    async retry () {
      this.dataSource.filter()
    },

    isSelectedItem (item) {
      return this.isMultipleSelection
        ? this.dataSource.itensSelected.some(itemSelected => JSON.stringify(itemSelected) === JSON.stringify(item))
        : JSON.stringify(this.dataSource.itensSelected) === JSON.stringify(item)
    },

    isDisabledItem (item) {
      return this.isMultipleSelection && this.dataSource.itensDisabled
        ? this.dataSource.itensDisabled.some(itemDisabled => JSON.stringify(itemDisabled) === JSON.stringify(item))
        : false
    },

    selectItem (event, item) {
      if (this.isMultipleSelection) {
        if (event.target.checked) {
          if (event.target.id === 'checkbox') {
            this.dataSource.itensSelected.push(item)
          }
        } else {
          this.dataSource.itensSelected = this.dataSource.itensSelected.filter(itemChecked => JSON.stringify(itemChecked) !== JSON.stringify(item))
        }
      } else {
        this.dataSource.itensSelected = item
      }

      this.anounceSelectedItems()
    },

    selectAllItens (event) {
      if (!this.dataSource.itensDisabled) {
        this.dataSource.itensSelected = event.target.checked ? this.dataSource.itens : []
      } else {
        this.dataSource.itensSelected = event.target.checked ? this.dataSource.itens.filter(i => !this.isDisabledItem(i)) : []
      }

      this.anounceSelectedItems()
    },

    anounceSelectedItems () {
      document.dispatchEvent(new CustomEvent('ce-alt-datatable-selecteditems', {
        detail: { itens: this.dataSource.itensSelected },
        composed: true
      }))
    }
  },

  computed: {
    checarSeTodosOsItensValidosEstaoSelecionados () {
      if (!this.dataSource.itensDisabled) {
        return this.dataSource.itens.length === this.dataSource.itensSelected.length
      } else {
        const itensValidos = this.dataSource.itens.filter(i => !this.isDisabledItem(i))
        return (itensValidos.length === this.dataSource.itensSelected.length)
      }
    }
  }
}

</script>

<style scoped>

.is-tableScrollable {
    overflow-y: auto;
    max-height: 70vh;
}

.is-tableScrollable  thead th {
    position: sticky;
    top: 0; z-index: 5
}

.is-tableScrollHorizontal {
    white-space: nowrap;
}

th {
    background:#FFF;
}

.is-visibleOverflow {
    overflow: visible !important;
}

#datatableFixed {
 table-layout:fixed;
 width:100%;
 white-space: nowrap;
}

#datatableCard {
 width:100%;
 white-space: nowrap;
}

#datatable-container-fixed {
  min-height: 120px;
  overflow: auto;
}

#datatable-container-card {
  overflow-x: hidden;
}

#conteudo-card {
  padding-bottom: 0rem;
  padding-top: 0rem;
}

</style>
