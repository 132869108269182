export const utils = {
  strZero (valor, tamanho) {
    if (!valor) {
      return ''
    }

    if (isNaN(Number(valor))) {
      return ''
    }

    return valor.toString().padStart(tamanho, '0')
  },

  ordenarArrayNumericamente (array) {
    return array.sort(compararNumeros)

    function compararNumeros (a, b) {
      return a - b
    }
  },

  formatarCampoDataParaEnvioAoBackend (data) {
    if (data && this.isDataValida(new Date(data))) {
      return new Date(data).toISOString()
    } else {
      return null
    }
  },

  formatarDataParaExibicao (data) {
    if (data && this.isDataValida(new Date(data))) {
      return data.substring(0, 10)
    } else {
      return null
    }
  },

  formatarDataParaExibicaoMesAno (data) {
    if (data && this.isDataValida(new Date(data))) {
      return this.formatarDataParaExibicao(data).substring(3, 10)
    }
  },

  formatarDataParaExibicaoNoDataTable (data) {
    if (data && this.isDataValida(new Date(data))) {
      data = new Date(data)
      data = data.setHours(data.getHours() + 3)
      return new Intl.DateTimeFormat('pt-br').format(new Date(data))
    }
  },

  formatarCEP (str) {
    const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/
    if (re.test(str)) {
      return str.replace(re, '$1$2-$3')
    }
    return ''
  },

  isDataValida (value) {
    const instaciaDeUmaData = value instanceof Date && !isNaN(value)
    const dentroDePeriodoValido = (value >= new Date('1900-01-01')) && (value <= new Date('9999-12-31'))
    return instaciaDeUmaData && dentroDePeriodoValido
  },

  isDataDigitadaValida (value) {
    if (value) {
      let data = new Date(value)
      const instaciaDeUmaData = data instanceof Date && !isNaN(data)
      data = new Date(new Date(data).toISOString().substring(0, 10))
      const dentroDePeriodoValido = (data >= new Date('1900-01-01')) && (data <= new Date('9999-12-31'))
      return instaciaDeUmaData && dentroDePeriodoValido
    }
  },

  toUtcIsoString (date) {
    date.setUTCHours(0)
    date.setUTCMinutes(0)
    date.setUTCSeconds(0)
    date.setUTCMilliseconds(0)

    return date.toISOString()
  },

  isTelefone (telefone) {
    const er = new RegExp(/^\(?\d{2}\)?[\s-]?[\s9]?\d{4,5}-?\d{4}$/)
    return !!(er.test(telefone) && telefone.length > 9 && telefone.length < 12)
  },

  isEmail (email) {
    if (email) {
      // const er = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)?$/
      const er = /^([a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*)?$/
      if (!er.test(email)) {
        return false
      }
    }

    return true
  },

  onlyNumber (evt, noPoint = false) {
    var theEvent = evt || window.event
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    var regex = noPoint ? /^[\d]+$/ : /^[0-9.]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  },

  onlyForRegex (evt, regex) {
    const theEvent = evt || window.event
    let key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  },

  emailFormat (evt) {
    var theEvent = evt || window.event
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    var regex = /^[a-zA-Z0-9_.@-]$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  },

  paginate (array, page, limit) {
    if (page === 0) {
      page = 1
    }
    return array.slice((page - 1) * limit, page * limit)
  },

  inscricaoEstadualValida (inscricao) {
    if (inscricao) {
      var regex = /^[A-Za-z0-9]+$/
      return (regex.test(inscricao))
    } else {
      return false
    }
  },

  identidadeValida (inscricao) {
    if (inscricao) {
      var regex = /^[A-Za-z0-9./-]+$/
      return (regex.test(inscricao))
    } else {
      return false
    }
  },

  identidadeFormat (evt) {
    var theEvent = evt || window.event
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    var regex = /^[A-Za-z0-9./-]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  },

  inscricaoEstadualFormat (evt) {
    var theEvent = evt || window.event
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    var regex = /^[A-Za-z0-9]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  },

  findCountry (numberCountry, countries = [{}]) {
    return countries.find(e => e.cod === parseInt(numberCountry))
  },

  findNextSmallestId (arr = []) {
    const arrSortId = arr.sort((a, b) => a.id - b.id)
    for (let i = 0; i < arrSortId.length; i++) {
      if (arrSortId[i].id !== i + 1) {
        return i + 1
      }
    }
    return arrSortId[arrSortId.length - 1].id + 1
  },

  clearMaskNumber (value = '') {
    if (value && value !== '') {
      return value instanceof String ? value.replace(/[^\d]+/g, '') : String(value).replace(/[^\d]+/g, '')
    }
    return value
  },
  ufibge (codigoUf) {
    let cdUf = ''
    switch (codigoUf.toUpperCase()) {
      case 'RO':
        cdUf = '11'
        break
      case 'AC':
        cdUf = '12'
        break
      case 'AM':
        cdUf = '13'
        break
      case 'RR':
        cdUf = '14'
        break
      case 'PA':
        cdUf = '15'
        break
      case 'AP':
        cdUf = '16'
        break
      case 'TO':
        cdUf = '17'
        break
      case 'MA':
        cdUf = '21'
        break
      case 'PI':
        cdUf = '22'
        break
      case 'CE':
        cdUf = '23'
        break
      case 'RN':
        cdUf = '24'
        break
      case 'PB':
        cdUf = '25'
        break
      case 'PE':
        cdUf = '26'
        break
      case 'AL':
        cdUf = '27'
        break
      case 'SE':
        cdUf = '28'
        break
      case 'BA':
        cdUf = '29'
        break
      case 'MG':
        cdUf = '31'
        break
      case 'ES':
        cdUf = '32'
        break
      case 'RJ':
        cdUf = '33'
        break
      case 'SP':
        cdUf = '35'
        break
      case 'PR':
        cdUf = '41'
        break
      case 'SC':
        cdUf = '42'
        break
      case 'RS':
        cdUf = '43'
        break
      case 'MS':
        cdUf = '50'
        break
      case 'MT':
        cdUf = '51'
        break
      case 'GO':
        cdUf = '52'
        break
      case 'DF':
        cdUf = '53'
        break
    }
    return cdUf
  },

  campoVazio (texto) {
    return (!texto || texto.toString().trim() === '')
  },

  ultimoValorDoSeparador (texto) {
    var ponto = texto.lastIndexOf('.')
    var barra = texto.lastIndexOf('/')
    var traco = texto.lastIndexOf('-')
    var array = []
    array.push(ponto, barra, traco)
    const maior = array.reduce((anterior, atual) => (anterior > atual) ? anterior : atual)
    return maior
  },

  primeiroValorDoSeparador (texto) {
    var ponto = texto.indexOf('.')
    var barra = texto.indexOf('/')
    var traco = texto.indexOf('-')
    var array = []
    if (ponto > 0) {
      array.push(ponto)
    }
    if (barra > 0) {
      array.push(barra)
    }
    if (traco > 0) {
      array.push(traco)
    }

    if (array.length > 0) {
      const primeiro = array.reduce((anterior, atual) => (anterior < atual) ? anterior : atual)
      return primeiro
    } else {
      return 0
    }
  },

  removeAcentos (string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  },

  formatarPercentual (percent) {
    const valor = parseFloat(percent.replace(',', '.'))
    return valor
  },

  gerarUUID () {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
  },

  converterImgParaTamanho (img) {
    const base64str = img.substring(22)
    const decodedsize = Buffer.from(base64str).length
    return decodedsize
  },

  objetosDiferentes (object1, object2, debug = false) {
    try {
      const prop1 = Object.getOwnPropertyNames(object1)
      const prop2 = Object.getOwnPropertyNames(object2)

      if (prop1.length !== prop2.length) {
        return false
      }
      for (const element of prop1) {
        const prop = element
        if (object1[prop] !== object2[prop]) {
          return true
        }
      }
    } catch (error) {
      return true
    }
    return false
  }
}
