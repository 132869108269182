import { utils } from '../../utils'

export default class ValidacaoIE {
  constructor (inscricao = '') {
    this.inscricao = inscricao
  }

  getSoma (ie = '', i = 0, peso = [0]) {
    return ie.charAt(i) * peso[i]
  }

  static zeroFill (valor, tamanho) {
    return utils.strZero(valor, tamanho)
  }

  validar () {
    return false
  }
}
