import { utils } from './utils'
import { INFORME, INVALIDO } from './message'
import Toast from '../components/ListaToastAviso/Toast/model/Toast'

export const mixin = {
  methods: {
    getStatus (value = '', texto) {
      if (value) {
        return value.includes('conteúdo do campo foi removido') || value.includes(texto) ? 'info' : 'error'
      }
      return ''
    },
    onlyNumber (e, noPoint = false) {
      return utils.onlyNumber(e, noPoint)
    },
    onlyEmail (e) {
      return utils.emailFormat(e)
    },
    isEmail (event, erros, erro, required = false) {
      const { value } = event.target
      if (required && !value) {
        erros[erro] = INFORME.EMAIL
      } else {
        const email = utils.isEmail(value)
        if (!email) {
          erros[erro] = value ? INVALIDO.EMAIL : ''
        }
      }
    },
    isTelefone (event, erros, erro, required = false) {
      const { value } = event.target
      if (required && value === '') {
        erros[erro] = INFORME.TELEFONE
      } else {
        const tel = utils.isTelefone(value)
        if (!tel) {
          erros[erro] = value ? INVALIDO.TELEFONE : ''
        }
      }
    },
    checkErrors (allErrors, errors, actions) {
      if (actions instanceof Array && actions.length > 0) {
        for (const element of actions) {
          element()
        }
      }
      return this.searchErrors(allErrors, errors)
    },

    async checkErrorsAsync (allErrors, errors, actions) {
      if (actions instanceof Array && actions.length > 0) {
        for (const element of actions) {
          await element()
        }
      }
      return this.searchErrors(allErrors, errors)
    },

    searchErrors (errors, error) {
      for (const element of error) {
        if (errors[element] !== undefined && errors[element] !== '') {
          return element
        }
      }
      return undefined
    },

    onlyCodes (arr = [], codeArr = []) {
      return arr.filter(e => {
        for (const element of codeArr) {
          if (Number(e.codigo) === element) {
            return e
          }
        }
      })
    },

    adicionarNotificacao (toast) {
      if (toast instanceof Toast) {
        const listaNotificacao = this.$root.listaNotificacao
        if (listaNotificacao instanceof Array) {
          const result = listaNotificacao.find(e => {
            if (e instanceof Toast) {
              return e.titulo === toast.titulo && e.subtitulo === toast.subtitulo
            }
          })
          if (!result) {
            toast.identificador = ++this.$root.identificadorNotificacao
            listaNotificacao.push(toast)
          }
        }
      }
    },
    removerNotificacao (id) {
      const listaNotificacao = this.$root.listaNotificacao
      if (listaNotificacao instanceof Array) {
        const result = listaNotificacao.findIndex(e => {
          if (e instanceof Toast) {
            return e.identificador === id
          }
        })
        if (result || result === 0) {
          listaNotificacao.splice(result, 1)
        }
      }
    },
    validarRequired (valor, texto) {
      if (!valor) {
        return texto
      } else {
        return ''
      }
    },

    focarNoPrimeiroInput (divDeCadastro, paramElemento) {
      try {
        const inputs = document.querySelectorAll(`#${divDeCadastro} input:not(:disabled):not([type="hidden"])`)
        const elemento = document.querySelectorAll(`#${divDeCadastro} ${paramElemento}:not(:disabled):not([type="hidden"])`)
        let firstVisibleInput

        for (const element of inputs) {
          const input = element
          if (input.offsetParent !== null) {
            firstVisibleInput = input
            break
          }
        }
        if (firstVisibleInput) {
          firstVisibleInput.focus()
        } else {
          if (paramElemento) {
            const localElemento = elemento[0]
            if (localElemento.offsetParent !== null) {
              localElemento.focus()
            }
          }
        }
      } catch (err) {
        console.warn(err)
      }
    },
    focarNextInput (input) {
      const foco = document.querySelector(input)
      this.$nextTick(() => {
        if (foco) {
          foco.focus()
        }
      })
    },
    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      if (
        !utils
          .removeAcentos(stringfied)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfied).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    }
  }
}
