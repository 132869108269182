<template>
    <div class="column is-12 p-0 pb-2" style="font-size: 16px">
      <p><strong>Trava Contábil</strong></p>
    </div>
  <div>
    <checkbox v-model="this.travaContabil" :checked="this.travaContabil" caption="Usar o recurso de Trava Contábil nos sistemas Alterdata Pack"></checkbox>
    <div class="column is-10">
      <div class="is-flex is-justify-content-flex-end">
      <Button type="primary" caption="Gravar" @click="configurarTrava"/>
        <Button class="ml-2" type="secondary" caption="Cancelar" @click="aoCancelar"/>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@alterdata/component-vue/Checkbox'
import Button from '@alterdata/component-vue/Button/index.vue'
import { service } from '@/service.js'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'

export default {
  components: {
    Checkbox,
    Button
  },
  mixins: [mixinRouterGuardian],
  inject: ['setRootData', 'rootData'],
  data () {
    return {
      trava: {
        name: 'WPHD',
        section: 'TravaContabil',
        ident: 'UtilizaTrava',
        value: '',
        memo: '',
        regtype: null
      },
      travaContabil: null,
      travaContabilOriginal: null
    }
  },

  computed: {
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    }
  },

  watch: {
    verificandoDadosAlteradosRoot (val) {
      if (val) {
        this.verificarModificaoNoItem()
      }
    }
  },

  methods: {
    async carregar () {
      const trava = await service.get('/api/v1/gerais')

      if (trava.value === 'S') {
        this.travaContabil = true
      } else {
        this.travaContabil = false
      }
      this.travaContabilOriginal = this.travaContabil
    },

    configurarTrava () {
      if (this.travaContabil) {
        this.trava.value = 'S'
      } else {
        this.trava.value = 'N'
      }
      this.travaContabilOriginal = this.travaContabil

      service.save('/api/v1/gerais', this.trava)

      this.redirecionarAposConfigurarTrava()

      this.dadosInalterados()
    },

    redirecionarAposConfigurarTrava () {
      const rotaAtual = this.$route.path
      const rotaClicada = sessionStorage.getItem('rotaClicadaNoMenuLateral')
      if (rotaAtual !== rotaClicada) {
        if (!this.verificarSeExisteRotaNaSession()) {
          this.$router.push('/inicio')
        }
      } else {
        this.$router.push('/inicio')
      }
    },

    aoCancelar () {
      sessionStorage.removeItem('rotaClicadaNoMenuLateral')
      sessionStorage.setItem('rotaClicadaNoMenuLateral', '/inicio')
      if (!this.verificarModificaoNoItem()) {
        this.$router.push('/inicio')
      }
    },

    verificarModificaoNoItem () {
      const existeModificacao = (this.travaContabil !== this.travaContabilOriginal)

      if (existeModificacao) {
        this.setRootData((data) => {
          data.dadosAlterados = true
          data.mostrarModalDadosAlterados = true
        })
      } else {
        this.setRootData((data) => {
          data.dadosAlterados = false
        })
      }
      return existeModificacao
    }
  },

  async mounted () {
    this.listagemVisivel = false
    await this.carregar()
  }
}
</script>

<style lang="scss" scoped>

</style>
