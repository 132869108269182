<template>
  <section class="modal-card-body" style="height: 80vh">
    <div class="columns is-multiline" style="min-height: 85%; padding: 0px">
      <div class="column is-12" style="min-height: 85%">
        <div class="columns is-multiline">
          <div id ="usuarioLogin" class="column is-12">
            <input-text
              class="is-uppercase"
              v-model.trom="this.item.nmcurto"
              id="usuario-login"
              name="nmcurto"
              label="Login"
              maxlength="20"
              required
              :disabled="this.editando"
              :status="getStatus(this.erros.nmcurto)"
              :message="this.erros.nmcurto"
              @update:erro="this.erros.nmcurto = $event.message"
              @blur="this.verificarUnicidadeDeLogin"
            />
          </div>
          <div id="idPassword" class="column is-6">
            <input-password
              id="usuario-senha"
              name="senha"
              maxlength="35"
              label="Senha"
              v-model="this.senha"
              :disabledButtonShowPass="this.senha === ''"
              :required="senhaRequired"
              :lengthPlaceholder="this.item.lengthPass"
              :message="this.erros.senha"
              :status="this.getStatus(this.erros.senha)"
              @update:erro="this.erros.senha = $event.message"
              @blur="verificarSenhas('senha')"
            />
          </div>
          <div  class="column is-6">
            <input-password
              id="usuario-confirmar-senha"
              name="confirmarSenha"
              label="Confirmar senha"
              maxlength="35"
              v-model="this.confirmarSenha"
              :disabledButtonShowPass="this.confirmarSenha === ''"
              :required="senhaRequired"
              :lengthPlaceholder="this.item.lengthPass"
              :message="this.erros.confirmarSenha"
              :status="this.getStatus(this.erros.confirmarSenha)"
              @update:erro="this.erros.confirmarSenha = $event.message"
              @blur="verificarSenhas('confirmarSenha')"
            />
          </div>
          <div class="column is-12">
            <input-text
              v-model="this.item.email"
              id="usuario-email"
              name="email"
              label="Email"
              maxlength="100"
              required
              :message="this.erros.email"
              :status="this.getStatus(this.erros.email)"
              @keypress="onlyEmail"
              @update:erro="this.erros.email = $event.message"
              @blur="verificaEmail"
            />
          </div>
          <div class="column is-10">
            <input-text
              v-model.trim="this.item.nmusuario"
              id="usuario-nome-usuario"
              name="nmusuario"
              label="Nome completo"
              maxlength="50"
              :message="this.erros.nmusuario"
              :status="this.getStatus(this.erros.nmusuario)"
              @update:erro="this.erros.nmusuario = $event.message"
            />
          </div>
          <div class="column is-2" v-if="this.item.nmcurto !== 'SUPERVISOR'">
            <div
              style="min-width: 100%; position: relative; top: 25px"
              class="is-flex is-justify-content-center is-align-items-center"
            >
              <Checkbox
                class="mr-1"
                v-model="this.item.usuarioDesativado"
                caption="Ativo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InputText from '@alterdata/component-vue/InputText'
import InputPassword from '@/components/InputPassword'
import Checkbox from '@alterdata/component-vue/Checkbox'
import Usuario from './model/Usuario'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
import { INFORME_LOGIN, LOGIN_CADASTRADO } from '@packonline/packonline-reutilizaveis-frontend/Utils/message'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
const API = '/api/v1/adusystem'

export default {
  name: 'CadastroEdicaoUsuario',
  components: {
    'input-password': InputPassword,
    InputText,
    Checkbox
  },
  emits: ['editando', 'carregarListagem'],
  inject: ['setActiveSection', 'addShortcut', 'setRootData'],
  props: {
    pItem: Usuario,
    titulo: String,
    editando: Boolean,
    focarTable: Function,
    linkAjuda: String
  },
  data () {
    return {
      item: this.pItem,
      itemOriginal: null,
      erros: {
        nmcurto: '',
        senha: '',
        confirmarSenha: '',
        email: '',
        nmusuario: ''
      },
      senha: '',
      confirmarSenha: '',
      senhaRequired: false,
      shortkeys: shortkeys
    }
  },
  mixins: [mixin, mixinModalSaida, mixinRouterGuardian],
  methods: {
    atribuirMetodosModalDadosAlterados () {
      this.setRootData((data) => {
        data.parametrosModalSalvarMudancas.eventoSalvar = () => {
          this.aoGravar()
        }

        data.parametrosModalSalvarMudancas.eventoSair = () => {
          this.sairSemSalvar()
        }
      })
    },

    async aoGravar () {
      this.status = 'is-loading'
      this.item.nmcurto = this.item.nmcurto.toUpperCase()
      const result = await this._verificarErros()
      if (!result) {
        try {
          if (this.item.idusuario) {
            await service.patch(API, this.item, this.item.idusuario)
          } else {
            await service.post(API, this.item)
          }
          this.status = ''
        } catch (error) {
          this.status = 'is-danger'
        } finally {
          this.item = new Usuario()
          this.$emit('carregarListagem')
          this.setRootData((data) => {
            data.paramentrosQuickView.componenteSlotQuickView = null
          })
        }
        this._redirecionarAposSalvarOuSairSemSalvar()
      }
      this.setRootData((data) => {
        data.impedirFechamentoDoQuickViewAoCancelar = false
      })
      this.dadosInalterados()
    },

    _redirecionarAposSalvarOuSairSemSalvar () {
      this.verificarSeExisteRotaNaSession()
    },

    _verificaObrigatoriedade () {
      if (this.senha || this.confirmarSenha) {
        this.senhaRequired = true
      } else {
        if (this.item.lengthPass) {
          this.senhaRequired = false
        }
      }
    },

    verificarSenhas (input = '') {
      this._verificaObrigatoriedade()
      if (this.senha && this.confirmarSenha) {
        this._confereSenhas()
      } else {
        this._verificaSenhasVazias(input)
      }
    },

    _confereSenhas () {
      const erroSenhaNaoConfere = 'As senhas não conferem'
      const senhaConfere = this.senha === this.confirmarSenha
      if (!senhaConfere) {
        this.erros.senha = erroSenhaNaoConfere
        this.erros.confirmarSenha = erroSenhaNaoConfere
        return true
      } else {
        if (!this.erros.senha.includes('conteúdo do campo')) {
          this.erros.senha = ''
        }
        if (!this.erros.confirmarSenha.includes('conteúdo do campo')) {
          this.erros.confirmarSenha = ''
        }
        this.item.senha = this.senha
        return false
      }
    },

    _verificaSenhasVazias (input) {
      const erroSenhaVazia = 'Informe a senha'
      if (this.senhaRequired) {
        this._atribuiErroAsSenhasVazias(erroSenhaVazia, input)
      } else {
        if (this.erros.senha === erroSenhaVazia) {
          if (!this.erros.senha.includes('conteúdo do campo')) {
            this.erros.senha = ''
          }
        }
        if (this.erros.confirmarSenha === erroSenhaVazia) {
          if (!this.erros.confirmarSenha.includes('conteúdo do campo')) {
            this.erros.confirmarSenha = ''
          }
        }
        return false
      }
    },

    _atribuiErroAsSenhasVazias (erroSenhaVazia, input) {
      if (!this.senha) {
        this.erros.senha = erroSenhaVazia
        if (this.erros.confirmarSenha !== erroSenhaVazia) {
          this.erros.confirmarSenha = ''
        }
      }
      if (!this.confirmarSenha) {
        if (input !== 'senha') {
          this.erros.confirmarSenha = erroSenhaVazia
          if (this.erros.senha !== erroSenhaVazia) {
            this.erros.senha = ''
          }
        } else {
          if (this.erros.confirmarSenha !== erroSenhaVazia) {
            this.erros.confirmarSenha = ''
          }
        }
      }
    },

    _verificarErros () {
      const erros = ['nmcurto', 'senha', 'confirmarSenha', 'email']
      this._limparErros()
      return this.verificarUnicidadeDeLogin().then(() => {
        const possuiErro = this.checkErrors(this.erros, erros, [
          () => {
            if (!this.item.nmcurto) {
              this.erros.nmcurto = 'Informe o login'
            }
            this.verificarSenhas()
            this.verificaEmail({ target: { value: this.item.email } })
          }
        ])
        if (possuiErro) {
          document.querySelector(`input[name="${possuiErro}"]`).focus()
          return true
        } else {
          return false
        }
      })
    },

    _limparErros () {
      Object.assign(this.erros, {
        nmcurto: '',
        senha: '',
        confirmarSenha: '',
        email: '',
        nmusuario: ''
      })
    },

    async verificarUnicidadeDeLogin () {
      if (!this.editando && this.item.nmcurto) {
        const res = await service.get(
          API + '/login-existente/' + this.item.nmcurto
        )
        if (res) {
          this.erros.nmcurto = LOGIN_CADASTRADO
        }
        return !res
      } else {
        if (!this.item.nmcurto) {
          this.erros.nmcurto = INFORME_LOGIN
          return false
        }
      }
      return false
    },

    verificaEmail (event) {
      this.isEmail(event, this.erros, 'email', true)
    },
    sairQuickview () {
      const existemDiferencas = this.verificarModificaoNoItem()
      if (existemDiferencas) {
        this.dadosAlterados()
      } else {
        this.setRootData((data) => {
          data.paramentrosQuickView.componenteSlotQuickView = null
        })
      }
    },

    verificarModificaoNoItem () {
      const item = this.formatarCadaItem(this.item)
      item.senha = this.senha
      item.confirmarSenha = this.confirmarSenha

      const itemOriginal = this.formatarCadaItem(this.itemOriginal)
      const existemDiferencas = !utils.isTotalmenteEqual(item, itemOriginal)

      if (existemDiferencas) {
        this.setRootData((data) => {
          data.dadosAlterados = true
          data.mostrarModalDadosAlterados = true
        })
      } else {
        this.setRootData((data) => {
          data.dadosAlterados = false
        })
      }
      return existemDiferencas
    },

    formatarCadaItem (item) {
      const itemLocal = utils.cloneDeep(item)
      delete itemLocal.aldescontomax
      delete itemLocal.crm
      itemLocal.confirmarSenha = ''
      return itemLocal
    },

    ligarAtalhosQuickview () {
      const section = 'quickviewUsuario'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, this.aoGravar)
      this.addShortcut(section, shortkeys.KEY_ESC, this.sairQuickview)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },

    sairSemSalvar () {
      this._redirecionarAposSalvarOuSairSemSalvar()
      this.setRootData((data) => {
        data.paramentrosQuickView.componenteSlotQuickView = null
      })
      this.dadosInalterados()
      this.$nextTick(() => {
        this.focarTable()
      })
    },

    direcionaAjuda () {
      this.$nextTick(() => {
        window.open(this.linkAjuda, '_blank').focus()
      })
    }
  },
  computed: {
    verificandoDadosAlteradosRoot () {
      return this.$root.verificandoDadosAlterados
    }
  },
  watch: {
    pItem (val) {
      this.item = val
      this.senha = val.senha
      this.confirmarSenha = val.senha
    },
    verificandoDadosAlteradosRoot (val) {
      if (val) {
        this.verificarModificaoNoItem()
      }
    }
  },
  created () {
    this.ligarAtalhosQuickview()
    this.itemOriginal = utils.cloneDeep(this.item)
    this.senha = this.pItem.senha
    this.confirmarSenha = this.pItem.senha
    this.setRootData((data) => {
      data.paramentrosQuickView.eventoSalvar = this.aoGravar
      data.paramentrosQuickView.eventoCancelar = this.sairQuickview
    })
    this.dadosInalterados()
    this.atribuirMetodosModalDadosAlterados()

    if (!this.item.lengthPass) {
      this.senhaRequired = true
    }
  },
  mounted () {
    this.listagemVisivel = false
    if (!this.editando) {
      this.focarNoPrimeiroInput('usuarioLogin')
    } else {
      this.focarNoPrimeiroInput('idPassword')
    }
  },
  unmounted () {
    this.listagemVisivel = true
  }
}
</script>

<style>
</style>
