export default class InfContador {
  constructor (infContador) {
    this.fax = ''
    this.telfax = ''
    this.email = ''
    this.optantesimples = ''
    this.incentivadorcultural = ''
    this.telefonecelular = ''
    this.datanascimento = ''
    this.cep = ''
    this.telefone = ''
    this.inscricaomunicipal = ''
    this.cpfresponsavel = ''
    this.creci = ''
    this.responsavel = ''
    this.crmcodigo = ''
    this.cidade = ''
    this.cdmunicipio = ''
    this.uf = ''
    this.cpfcgc = ''
    this.estabelecimento = ''
    this.endereco = ''
    this.bairro = ''
    if (infContador) {
      Object.assign(this, infContador)
    }
  }
}
