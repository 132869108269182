<template>
  <InputText
    name="cpf-cnpj"
    v-bind="{ ...$attrs, ...$props }"
    :label="props.type.toUpperCase()"
    :maxlength="maxlength"
    @paste="handlePaste"
    @blur="handleBlur()"
    @focus="handleFocus()"
    @input="_emitErrorOnInputEmpty('')"
    @keypress="utils.onlyNumber($event, true)"
    @update:erro="$emit('update:erro', $event)"
    @update:modelValue="$emit('update:modelValue', $event)"
    :data-group=" dataGroup ? 'dtgp-cpf-cnpj-' + dataGroup : 'dtgp-cpf-cnpj'"
  />
</template>

<script setup>
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { validadorDeInscricaoFederal } from '../../util/InscricaoFederal'
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue'
import { utils } from '../../util/utils'
import {
  CONTEUDO_INVALIDO,
  CNPJ_CPF_INVALIDO,
  INFORME_CPF_CNPJ,
  CNPJ_INVALIDO,
  CPF_INVALIDO
} from '../../util/message'

const props = defineProps({
  // Os tipos podem ser: CPF, CNPJ e CNPJ/CPF
  type: {
    type: String,
    default: 'CNPJ/CPF'
  },
  modelValue: String,
  required: {
    type: Boolean,
    default: false
  },
  dataGroup: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:modelValue', 'update:erro'])

const maxlength = ref(11)

function _validateCPF (message = CPF_INVALIDO) {
  const result = validadorDeInscricaoFederal.validarCPF(props.modelValue)
  if (!result) {
    emits('update:erro', { message: message })
  } else {
    const cpfMask = _applyMask('CPF')
    emits('update:modelValue', cpfMask)
  }
}

function _validateCNPJ (message = CNPJ_INVALIDO) {
  const result = validadorDeInscricaoFederal.validarCNPJ(props.modelValue)
  if (!result) {
    emits('update:erro', { message: message })
  } else {
    const cnpjMask = _applyMask('CNPJ')
    emits('update:modelValue', cnpjMask)
  }
}

function _validateCNPJorCPF () {
  const inscricao = utils.clearMaskNumber(props.modelValue)
  if (inscricao.length > 11) {
    _validateCNPJ(CNPJ_CPF_INVALIDO)
  } else {
    _validateCPF(CNPJ_CPF_INVALIDO)
  }
}

function _applyMask (type) {
  if (props.modelValue) {
    const inscricao = utils.clearMaskNumber(props.modelValue)
    if (type === 'CPF') {
      return validadorDeInscricaoFederal.aplicarMascaraCpf(inscricao)
    }
    if (type === 'CNPJ') {
      return validadorDeInscricaoFederal.aplicarMascaraCnpj(inscricao)
    }
  }
}

function _emitErrorOnInputEmpty (message = INFORME_CPF_CNPJ) {
  emits('update:erro', { message: message })
}

function _checkIntegrity (value = '') {
  const regex = /^[\d./-]+$/
  return regex.test(value)
}

function handleBlur () {
  if (props.modelValue) {
    if (_checkIntegrity(props.modelValue)) {
      if (props.type === 'CPF') {
        _validateCPF()
      } else if (props.type === 'CNPJ') {
        _validateCNPJ()
      } else {
        _validateCNPJorCPF()
      }
    } else {
      _clearModelValue()
    }
  } else {
    if (props.required) {
      const erro = `Informe o ${props.type.toUpperCase()}`
      _emitErrorOnInputEmpty(erro)
    }
  }
}

function _clearModelValue () {
  emits('update:modelValue', '')
  emits('update:erro', { message: CONTEUDO_INVALIDO })
}

function _clearMaskAndUpdateModelValue () {
  const modelValueClearMask = utils.clearMaskNumber(props.modelValue)
  emits('update:modelValue', modelValueClearMask)
}

function handleFocus () {
  maxlength.value = props.type === 'CPF' ? 14 : 18
  _clearMaskAndUpdateModelValue()
}

function handlePaste (event) {
  maxlength.value = props.type === 'CPF' ? 14 : 18
  const inputValue = event.clipboardData.getData('text')
  if (_checkIntegrity(inputValue)) {
    _clearMaskAndUpdateModelValue()
  }
}

watch(
  () => props.type,
  () => {
    handleBlur()
  }
)

onMounted(() => {
  if (props.modelValue) {
    const type = props.modelValue.length > 11 ? 'CNPJ' : 'CPF'
    const cpfOrCnpjMask = _applyMask(type)
    emits('update:modelValue', cpfOrCnpjMask)
  }
})
</script>

<style>
</style>
