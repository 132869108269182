import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIEPE extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 9 && this.inscricao.length !== 14) {
      return false
    }

    const digito1 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 2, this.inscricao.length - 1))
    const digito2 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))

    if (this.inscricao.length === 14) {
      const digitoCalculado2para14 = this._calcularDigito2()
      return digito2 === digitoCalculado2para14
    }

    const digitoCalculado1 = this._calcularDigito1()
    const digitoCalculado2 = this._calcularDigito2()

    return digitoCalculado1 === digito1 && digitoCalculado2 === digito2
  }

  _retornaPeso (ie = '') {
    if (ie.length === 8) {
      return [9, 8, 7, 6, 5, 4, 3, 2]
    } else {
      if (ie.length === 7) {
        return [8, 7, 6, 5, 4, 3, 2]
      }
      return [5, 4, 3, 2, 1, 9, 8, 7, 6, 5, 4, 3, 2]
    }
  }

  _retornarDVparaIE13Digitos (soma = 0) {
    const digito = 11 - soma % 11
    return digito > 9 ? digito - 10 : digito
  }

  _calcularDigitoVerificador (peso = [0], ie = '') {
    let soma = 0
    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }
    let teste
    if (this.inscricao.length === 14) {
      teste = this._retornarDVparaIE13Digitos(soma)
    } else {
      teste = soma % 11 <= 1 ? 0 : 11 - (soma % 11)
    }
    return teste
  }

  _calcularDigito1 () {
    const ie = this.inscricao.substring(0, this.inscricao.length - 2)
    const peso = this._retornaPeso(ie)

    return this._calcularDigitoVerificador(peso, ie)
  }

  _calcularDigito2 () {
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)
    const peso = this._retornaPeso(ie)

    return this._calcularDigitoVerificador(peso, ie)
  }
}
