import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIEAP extends ValidacaoIE {
  constructor (inscricao) {
    super(inscricao)
    this._p = 0
    this._d = 0
  }

  validar () {
    if (this.inscricao.length !== 9) {
      return false
    }

    const peso = [9, 8, 7, 6, 5, 4, 3, 2]
    const prefixo = Number.parseInt(this.inscricao.substring(0, 2))
    const digito = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)

    const digitoCalculado = this._calcularDigitoVerificar(peso, ie)

    return digitoCalculado === digito && prefixo === 3
  }

  _calcularPeD (intIe) {
    if (intIe >= 3000001 && intIe <= 3017000) {
      this._p = 5
      this._d = 0
    } else if (intIe >= 3017001 && intIe <= 3019022) {
      this._p = 9
      this._d = 1
    } else {
      this._p = 0
      this._d = 0
    }
  }

  _calcularDigitoVerificar (peso = [], ie = '') {
    const intIe = Number.parseInt(ie)
    let soma = 0

    this._calcularPeD(intIe)

    for (let i = 0; i < ie.length; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    soma += this._p
    const resto = soma % 11

    if (resto === 1) {
      return 0
    } else {
      if (resto === 0) {
        return this._d
      }
      return 11 - resto
    }
  }
}
