<template>
  <alt-button
    :disabled="itens.length === 0 || isCarregando"
    :loading="carregandoIntegracaoEmLote"
    class="mr-1"
    type="secondary"
    caption="Integrar em lote"
    icon="link"
    @click="integrarEmLote"
  />
  <alt-button
    :disabled="itens.length === 0 || isCarregando"
    :loading="carregandoDesfazendoIntegracaoEmLote"
    type="secondary"
    caption="Desfazer em lote"
    icon="unlink"
    @click="desfazerEmLote"
  />
</template>

<script>
import Button from '@alterdata/component-vue/Button/index.vue'
import Toast from '../../../../components/ListaToastAviso/Toast/model/Toast'
import { mixin } from '../../../../util/mixin'

import { integracaoEmLoteService } from './service/IntegracaoEmLoteService'

const API = '/api/v1/integracao-e-contador/'

export default {
  components: {
    'alt-button': Button
  },
  emits: ['atualizar', 'atualizar-status'],
  props: {
    itens: {
      type: Array,
      default: () => []
    }
  },
  mixins: [mixin],
  data () {
    return {
      isCarregando: false,
      carregandoIntegracaoEmLote: false,
      carregandoDesfazendoIntegracaoEmLote: false
    }
  },
  methods: {
    async integrarEmLote () {
      const filtro = item => !item.st_dc
      const empresasId = this._extrairIds(filtro)
      try {
        if (empresasId.length > 0) {
          this.isCarregando = true
          this.carregandoIntegracaoEmLote = true
          await integracaoEmLoteService.post(
            API.concat('integrar-lote'),
            empresasId
          )
          this.carregandoIntegracaoEmLote = false
          this.isCarregando = false
          this.$emit('atualizar')
        }
      } catch (err) {
        if (err.message.includes('Efetue novamente a integração destas empresas')) {
          const htmlStr = `<p>${err.message}</p><br>`
          const notificao = new Toast({ tipo: 'warning', titulo: 'Não foi possível concluir o processo de integração para todas as empresas.', subtitulo: htmlStr })
          this.adicionarNotificacao(notificao)
        } else {
          this.$emit('atualizar-status', 'is-danger')
        }
      } finally {
        this.isCarregando = false
        this.carregandoIntegracaoEmLote = false
      }
    },
    async desfazerEmLote () {
      const filtro = item => item.st_dc
      const empresasId = this._extrairIds(filtro)
      try {
        if (empresasId.length > 0) {
          this.isCarregando = true
          this.carregandoDesfazendoIntegracaoEmLote = true
          await integracaoEmLoteService.post(
            API.concat('desfazer-integracao-lote'),
            empresasId
          )
          this.carregandoDesfazendoIntegracaoEmLote = false
          this.isCarregando = false
          this.$emit('atualizar')
        }
      } catch {
        this.$emit('atualizar-status', 'is-danger')
      } finally {
        this.isCarregando = false
        this.carregandoDesfazendoIntegracaoEmLote = false
      }
    },
    _extrairIds (filtro) {
      return this.itens.filter(filtro).map((empresa) => empresa.cdempresa)
    }
  }
}
</script>

<style></style>
