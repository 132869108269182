import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIERO extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 9 && this.inscricao.length !== 14) {
      return false
    }

    const ie = this.inscricao.length === 14 ? this.inscricao.substring(0, this.inscricao.length - 1) : ValidacaoIE.zeroFill(this.inscricao, 13)
    const digito = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))

    const digitoCalculado = this._calcularDigitoVerificador(ie)

    return digitoCalculado === digito
  }

  _calcularDigitoVerificador (ie = '') {
    const peso = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    let soma = 0

    for (let i = 0; i < ie.length; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    const resto = 11 - soma % 11

    return resto >= 10 ? resto - 10 : resto
  }
}
