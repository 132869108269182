import ValidacaoIE from './ValidacaoIE'

export default class ValidacaoIESP extends ValidacaoIE {
  validar () {
    if (this.inscricao.length !== 12) {
      return false
    }

    const digito1 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 4, this.inscricao.length - 3))
    const digito2 = Number.parseInt(this.inscricao.substring(this.inscricao.length - 1))

    const digitoCalculado1 = this._calculaDigito1()
    const digitoCalculado2 = this._calculaDigito2()

    return (digito1 === digitoCalculado1) && (digito2 === digitoCalculado2)
  }

  _calcularDigitoVerificador (peso = [], ie = '') {
    let soma = 0

    for (let i = 0; i <= ie.length - 1; i++) {
      soma += super.getSoma(ie, i, peso)
    }

    return Number.parseInt(soma % 11 >= 10 ? 0 : soma % 11)
  }

  _calculaDigito1 () {
    const peso = [1, 3, 4, 5, 6, 7, 8, 10]
    const ie = this.inscricao.substring(0, 8)

    return this._calcularDigitoVerificador(peso, ie)
  }

  _calculaDigito2 () {
    const peso = [3, 2, 10, 9, 8, 7, 6, 5, 4, 3, 2]
    const ie = this.inscricao.substring(0, this.inscricao.length - 1)

    return this._calcularDigitoVerificador(peso, ie)
  }
}
