export default class Smtp {
  constructor (smtp) {
    this.smtp = ''
    this.porta = 0
    this.senha = ''
    this.conta = ''
    this.email = ''
    this.stAutenticacao = 0
    this.stCriptografia = 0
    this.stSmtpAlterdata = 0
    this.envioDeRelatorio = ''
    this.lengthPass = ''
    if (smtp) {
      Object.assign(this, smtp)
    }
  }
}
