<template>
  <div style="display: flex; align-items: center">
    <table>
      <tr class="table-row-style">
        <td style="width: 550px">
          <Label caption="Exibir uma linha com asteriscos ao final do Balanço e Diário" data-group="label-exibir-linha-com-asteristicos-ao-final-do-balanco-e-diario" />
        </td>
        <td>
          <SwitchToggle
            data-group="exibir-linha-com-asteristicos-ao-final-do-balanco-e-diario"
            :p-name="configuracoesDoDiario.ImprimeAsteriscosFimRelatorioDB"
            :p-value="switchExibirLinhaDoDiarioComAsteristicosAoFinalDoBalancoEDiadio"
            style="margin-right: 1px"
            @click="() => atualizarSwitch('switchExibirLinhaDoDiarioComAsteristicosAoFinalDoBalancoEDiadio', 'ImprimeAsteriscosFimRelatorioDB')"
          />
        </td>
      </tr>
      <br />
      <tr class="table-row-style">
        <td style="width: 550px">
          <Label caption="Exibir uma linha com asteriscos ao final do Balancete e do Razão" data-group="label-exibir-linha-asteriscos" />
        </td>
        <td>
          <SwitchToggle
            data-group="exibir-linha-asteriscos"
            :p-name="configuracoes.ImprimeAsteriscosFimRelatorioBR"
            :p-value="switchAsteriscos"
            style="margin-right: 1px"
            @click="() => atualizarSwitch('switchAsteriscos', 'ImprimeAsteriscosFimRelatorioBR')"
          />
        </td>
      </tr>
      <br />
      <tr class="table-row-style">
        <td style="width: 550px">
          <Label caption="Exibir data e hora da emissão no cabeçalho dos relatórios contábeis" data-group="label-exibir-data-hora" />
        </td>
        <td>
          <SwitchToggle
            data-group="exibir-data-hora"
            :p-name="configuracoes.StImprimeDataHora"
            :p-value="switchDataHora"
            style="margin-right: 1px"
            @click="() => atualizarSwitch('switchDataHora', 'StImprimeDataHora')"
          />
        </td>
      </tr>
      <br />
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import SwitchToggle from '@packonline/packonline-reutilizaveis-frontend/components/SwitchToggle/index.vue'
import Label from '@alterdata/component-vue/Label/index.vue'
import ConfiguracoesAdicionais from './ConfiguracoesAdicionais.js'
import ConfiguracoesAdicionaisDiario from './ConfiguracoesAdicionaisDiario.js'
import { microServiceRequest } from '@packonline/packonline-reutilizaveis-frontend/core/services/micro-service-request'
import { env } from '@/env'
const API = '/api/v1/livros/balancete'
const API_DIARIO = '/api/v1/livros/diario'

const configuracoes = ref(new ConfiguracoesAdicionais())
const configuracoesDoDiario = ref(new ConfiguracoesAdicionaisDiario())
const switchAsteriscos = ref(false)
const switchDataHora = ref(false)
const switchExibirLinhaDoDiarioComAsteristicosAoFinalDoBalancoEDiadio = ref(false)
const dataKey = ref(0)
const refs = {
  switchAsteriscos,
  switchDataHora,
  switchExibirLinhaDoDiarioComAsteristicosAoFinalDoBalancoEDiadio

}

onMounted(async () => {
  configuracoes.value = await microServiceRequest.get(env.msContabil, `${API}/balancete-configuracoes-adicionais`, null)
  populaConfiguracoesNaTela()
  configuracoesDoDiario.value = await microServiceRequest.get(env.msContabil, `${API_DIARIO}/diario-configuracoes-principais`, null)
  populaConfiguracoesDoDiarioNaTela()
})

const converteBooleanParaSN = (valor) => {
  return valor ? 'S' : 'N'
}
const converteSNParaBoolean = (valor) => {
  return valor === 'S'
}

const populaConfiguracoesDoDiarioNaTela = () => {
  switchExibirLinhaDoDiarioComAsteristicosAoFinalDoBalancoEDiadio.value = converteSNParaBoolean(configuracoesDoDiario.value.ImprimeAsteriscosFimRelatorioDB)

  nextTick(() => {
    dataKey.value++
  })
}

const populaConfiguracoesNaTela = () => {
  switchAsteriscos.value = converteSNParaBoolean(configuracoes.value.ImprimeAsteriscosFimRelatorioBR)
  switchDataHora.value = converteSNParaBoolean(configuracoes.value.StImprimeDataHora)
  configuracoes.value.StClassExterna = 'N'
  configuracoes.value.stBloqueiaImpressao = 'N'
  nextTick(() => {
    dataKey.value++
  })
}

const atualizarSwitch = (switchRef, valueRef) => {
  refs[switchRef].value = !refs[switchRef].value
  if (switchRef === 'switchExibirLinhaDoDiarioComAsteristicosAoFinalDoBalancoEDiadio') {
    configuracoesDoDiario.value[valueRef] = converteBooleanParaSN(refs[switchRef].value)
    ConfiguracoesAdicionaisDiario.salvaOpcoes(configuracoesDoDiario.value)
  } else {
    configuracoes.value[valueRef] = converteBooleanParaSN(refs[switchRef].value)
    ConfiguracoesAdicionais.salvaOpcoes(configuracoes.value)
  }
}
</script>

<style lang="scss" scoped></style>
