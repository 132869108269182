<template>
  <InputText
    :type="showPass ? 'password' : 'text'"
    :placeholder="
      lengthPlaceholder && lengthPlaceholder !== 0 ? fakePlaceholder : ''
    "
    maxlength="255"
  >
    <template #addon>
      <Button
        tabindex="-1"
        :class="{ 'is-danger is-outlined': $attrs.status === 'error' }"
        :disabled="disabledButtonShowPass"
        caption=""
        icon-only="eye"
        @click="showPass = !showPass"
      />
    </template>
  </InputText>
</template>

<script setup>
import InputText from '@alterdata/component-vue/InputText'
import Button from '@alterdata/component-vue/Button'
import { ref, defineProps, onMounted, watch } from 'vue'

const showPass = ref(true)

const fakePlaceholder = ref('')

const props = defineProps({
  lengthPlaceholder: {
    type: String,
    default: '0'
  },
  disabledButtonShowPass: {
    type: Boolean
  }
})

function zeroPedCircle (length) {
  return '•'.padStart(length, '•')
}

function definePlaceholder () {
  let value
  try {
    value = zeroPedCircle(Number(props.lengthPlaceholder.length))
  } catch (error) {
    value = zeroPedCircle(0)
  }
  return value
}
onMounted(() => {
  fakePlaceholder.value = definePlaceholder()
})

watch(
  () => props.lengthPlaceholder,
  () => {
    fakePlaceholder.value = definePlaceholder()
  }
)
</script>

<style scoped></style>
