const CONTEUDO_INVALIDO = 'O conteúdo do campo foi removido, pois continha caracteres inválidos.'
const CODIGO_INVALIDO = 'Código inválido'
const EMAIL_INVALIDO = 'E-mail inválido'
const TELEFONE_INVALIDO = 'Telefone inválido'
const CPF_INVALIDO = 'CPF inválido'
const CNPJ_INVALIDO = 'CNPJ inválido'
const CNPJ_CPF_INVALIDO = 'CNPJ/CPF inválido'
const VALOR_INVALIDO = 'Valor inválido'

const INFORME_EMAIL = 'Informe o e-mail'
const INFORME_TELEFONE = 'Informe o telefone'
const INFORME_CONTADOR = 'Informe o contador'
const INFORME_CPF = 'Informe o CPF'
const INFORME_CNPJ = 'Informe o CNPJ'
const INFORME_CPF_CNPJ = 'Informe o CNPJ/CPF'
const INFORME_LOGIN = 'Informe o login'

const LOGIN_CADASTRADO = 'Login já cadastrado'

const INFORME = {
  TELEFONE: INFORME_TELEFONE,
  EMAIL: INFORME_EMAIL,
  CONTADOR: INFORME_CONTADOR,
  CPF: INFORME_CPF,
  CNPJ: INFORME_CNPJ,
  CNPJ_CPF: INFORME_CPF_CNPJ,
  LOGIN: INFORME_LOGIN
}

const INVALIDO = {
  TELEFONE: TELEFONE_INVALIDO,
  EMAIL: EMAIL_INVALIDO,
  CPF: CPF_INVALIDO,
  CNPJ: CNPJ_INVALIDO,
  CNPJ_CPF: CNPJ_CPF_INVALIDO,
  CODIGO: CODIGO_INVALIDO,
  VALOR: VALOR_INVALIDO
}

export {
  CONTEUDO_INVALIDO,
  EMAIL_INVALIDO,
  CPF_INVALIDO,
  CNPJ_INVALIDO,
  CNPJ_CPF_INVALIDO,
  CODIGO_INVALIDO,
  INFORME_EMAIL,
  INFORME_TELEFONE,
  INFORME_CNPJ,
  INFORME_CPF,
  INFORME_CPF_CNPJ,
  TELEFONE_INVALIDO,
  VALOR_INVALIDO,
  INFORME_CONTADOR,
  INFORME,
  INVALIDO,
  LOGIN_CADASTRADO,
  INFORME_LOGIN
}
