import { Service } from '../../../service.js'

class EmpresaService extends Service {
  async delete (url, codigo) {
    const init = {
      headers: this.headers,
      method: 'DELETE'
    }

    const response = await fetch(this.api + url + '/' + codigo, init)
    if (response.ok) {
      return response
    }

    let erro
    await response.json().then(data => {
      erro = data
      if (erro.message === 'Inconsistent tenant information') {
        window.location.href = '/erroAcesso'
      }
    })
    throw new Error(erro.message)
  }

  async patch (url, dto) {
    const init = {
      headers: this.headers,
      method: 'PATCH',
      body: JSON.stringify(dto)
    }
    return fetch(`${this.api}${url}/${dto.cdempresa}`, init).then(response => response)
  }

  async save (url, dto, editando) {
    if (!editando) {
      return this.post(url, dto)
    }
    return this.patch(url, dto)
  }

  async consultaCnpj (url, dto) {
    return this.get(`${url}?cnpj=${dto}`)
  }

  async consultaCnpjLivre (url, dto) {
    return this.get(`${url}?cnpj=${dto}`)
  }
}
export const empresaService = new EmpresaService()
