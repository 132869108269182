class Usuario {
  constructor (usuario) {
    this.idusuario = ''
    this.nmusuario = ''
    this.nmcurto = ''
    this.senha = ''
    this.aldescontomax = ''
    this.email = ''
    this.usuarioDesativado = true
    this.lengthPass = ''
    this.crm = ''
    if (usuario) {
      Object.assign(this, usuario)
    }
  }
}
export default Usuario
